.Registration {
	padding: 0rem 0rem 6rem 0rem;
}

.Registration form {
	margin: 0 auto;
	max-width: 320px;
}

/* BELOW HERE MORE WORK */

.Registration form section {
	display: flex;
	width: 100%;
	margin-top: 1rem;
}

.Registration form section button {
	width: 320px;
}

.registration-form {
	text-align: left !important;
}

.registration-submit-btn button {
	background-color: #333399;
	border-color: #333399;
}

.registration-submit-btn button:hover,
.registration-submit-btn button:focus,
.registration-submit-btn button:active {
	background-color: #24256e;
	border-color: #24256e;
}

#fg3,
#fg4,
#fg4a,
#fg4b,
#fg4c,
#fg4d {
	margin-top: 1rem;
}

.no-side-padding-register {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.left-side-padding-register {
	padding-left: 3px !important;
	padding-right: 0px !important;
}

.right-side-padding-register {
	padding-left: 0px !important;
	padding-right: 3px !important;
}

.contain-row-register {
	margin-left: 0px !important;
	margin-right: 0px !important;
	max-width: 100%;
}

.neutral {
	display: none !important;
}

.please-display {
	display: flex !important;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.do-not-display {
	display: none !important;
}

.nucleus-form-fields input {
	width: 100% !important;
	height: 38px !important;
}

.signature-pad {
	border: 1px solid #ccc;
}

.markdown-content {
	font-size: 10px; /* Default font size for mobile devices */
}

.terms-checkbox {
	margin-top: 1rem;
}

.pw-no-match {
	display: flex !important;
	color: red !important;
}

.invalid-pw {
	display: inline-block;
}

.markdown-content {
	/* Skeuomorphic enhancements */
	background: linear-gradient(
		to bottom,
		#ffffff 0%,
		#fafafa 100%
	); /* Subtle gradient */
	background-color: white; /* Set the background to white */
	border: 1px solid #d3d3d3; /* Light grey border for subtle definition */

	box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.27); /* Subtle inset shadow for depth */
	padding: 15px; /* Add some padding around the text */
	padding-top: 20px; /* Add some padding around the text */
	border-radius: 9px; /* Optional: rounded corners */

	text-shadow: 0 1.5px 0 #d3d3d3; /* Slight text shadow for raised effect */
	line-height: 1.6; /* Improved line height for readability */
	color: #333; /* Dark grey color for the text */
	margin-bottom: 1rem;
	overflow-y: auto; /* Enable vertical scrolling */
}

.signature-pad-skeuomorphic {
	background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
	border: 2px solid #ccc;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1),
		inset 0 1px 2px rgba(255, 255, 255, 0.6), 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 9px;
	padding: 10px;
	color: #333;
	text-align: center;
	width: 100%; /* Adjust based on your layout */
	height: 150px; /* Adjust based on your layout */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}

.modal-open .modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* For tablets and larger devices */
@media (min-width: 200px) {
	.markdown-content {
		font-size: 12px;
		height: 175px;
	}
}

/* For tablets and larger devices */
@media (min-width: 360px) {
	.markdown-content {
		font-size: 12px;
		height: 250px;
	}
}

/* For tablets and larger devices */
@media (min-width: 768px) {
	.markdown-content {
		font-size: 14px;
		height: 360px;
	}
}

/* For desktops and larger devices */
@media (min-width: 1024px) {
	.markdown-content {
		font-size: 16px; /* Maximum font size set by the body element */
		height: 360px;
	}
}
