@media (max-width: 767px) {
  .sm-landing {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .sm-landing .div {
    background-color: #ffffff;
    border: 1px none;
    height: 8050px;
    position: relative;
    width: 320px;
  }

  .sm-landing .hero {
    height: 1060px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 320px;
  }

  .sm-landing .row {
    height: 1060px;
    position: relative;
  }

  .sm-landing .col {
    height: 320px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .hero-img {
    height: 300px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 300px;
  }

  .sm-landing .overlap-group {
    height: 300px;
    position: relative;
  }

  .sm-landing .hero-block {
    background-color: #036136;
    border-radius: 8.82px;
    height: 265px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 35px;
    width: 265px;
  }

  .sm-landing .div-2 {
    background-color: #66d77f;
    border-radius: 66.18px;
    filter: blur(132.35px);
    height: 132px;
    left: 66px;
    position: relative;
    top: 66px;
    width: 132px;
  }

  .sm-landing .img {
    height: 265px;
    left: 35px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 265px;
  }

  .sm-landing .group-wrapper {
    height: 740px;
    left: 0;
    position: absolute;
    top: 320px;
    width: 320px;
  }

  .sm-landing .group {
    height: 740px;
    position: relative;
  }

  .sm-landing .text {
    height: 195px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .span-wrapper {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 180px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .text-wrapper {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 45px;
  }

  .sm-landing .div-wrapper {
    height: 110px;
    left: 10px;
    position: absolute;
    top: 195px;
    width: 300px;
  }

  .sm-landing .p {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 100px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .span {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }

  .sm-landing .text-2 {
    height: 35px;
    left: 10px;
    position: absolute;
    top: 305px;
    width: 300px;
  }

  .sm-landing .frame {
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .sm-landing .icon {
    height: 20px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
  }

  .sm-landing .element {
    height: 35px;
    left: 30px;
    position: absolute;
    top: 0;
    width: 270px;
  }

  .sm-landing .text-3 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 20px;
    font-weight: 700;
    height: 25px;
    left: 5px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 255px;
  }

  .sm-landing .text-wrapper-2 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
  }

  .sm-landing .text-4 {
    height: 85px;
    left: 10px;
    position: absolute;
    top: 340px;
    width: 300px;
  }

  .sm-landing .text-5 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 75px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .text-6 {
    height: 35px;
    left: 10px;
    position: absolute;
    top: 425px;
    width: 300px;
  }

  .sm-landing .text-7 {
    height: 160px;
    left: 10px;
    position: absolute;
    top: 460px;
    width: 300px;
  }

  .sm-landing .text-8 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 150px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .text-9 {
    height: 35px;
    left: 10px;
    position: absolute;
    top: 620px;
    width: 300px;
  }

  .sm-landing .text-10 {
    height: 85px;
    left: 10px;
    position: absolute;
    top: 655px;
    width: 300px;
  }

  .sm-landing .work {
    height: 1431px;
    left: 0;
    position: absolute;
    top: 1160px;
    width: 320px;
  }

  .sm-landing .overlap {
    height: 1401px;
    left: -12px;
    position: relative;
    width: 100%;
  }

  .sm-landing .img-2 {
    height: 1100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1100px;
  }

  .sm-landing .container {
    height: 1371px;
    left: 12px;
    position: absolute;
    top: 30px;
    width: 320px;
  }

  .sm-landing .text-11 {
    height: 71px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .text-12 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 24px;
    font-weight: 700;
    height: 56px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 300px;
  }

  .sm-landing .text-wrapper-3 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }

  .sm-landing .frame-2 {
    height: 960px;
    left: 0;
    position: absolute;
    top: 71px;
    width: 320px;
  }

  .sm-landing .card {
    height: 480px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .overlap-wrapper {
    background-color: #09305d;
    border-radius: 20px;
    height: 460px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 300px;
  }

  .sm-landing .overlap-2 {
    height: 502px;
    position: relative;
    width: 630px;
  }

  .sm-landing .card-bg {
    background-color: #09305d;
    border-radius: 20px;
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 620px;
  }

  .sm-landing .schedule-elipse {
    background-color: #669bd7;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 330px;
    position: absolute;
    top: 202px;
    width: 300px;
  }

  .sm-landing .overlap-group-wrapper {
    height: 120px;
    left: 0;
    position: absolute;
    top: 20px;
    width: 300px;
  }

  .sm-landing .overlap-group-2 {
    height: 120px;
    left: 30px;
    position: relative;
    width: 270px;
  }

  .sm-landing .text-13 {
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .sm-landing .text-14 {
    color: #669bd7;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 220px;
  }

  .sm-landing .text-wrapper-4 {
    color: #669bd7;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .sm-landing .text-15 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 240px;
  }

  .sm-landing .text-16 {
    color: #669bd7;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 220px;
  }

  .sm-landing .text-wrapper-5 {
    color: #669bd7;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .sm-landing .button {
    height: 90px;
    left: 180px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 90px;
  }

  .sm-landing .btn {
    height: 50px;
    left: 20px;
    position: relative;
    top: 20px;
    width: 50px;
  }

  .sm-landing .icon-2 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .sm-landing .schedule {
    height: 320px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 140px;
    width: 300px;
  }

  .sm-landing .card-2 {
    height: 480px;
    left: 0;
    position: absolute;
    top: 480px;
    width: 320px;
  }

  .sm-landing .group-2 {
    background-color: #036136;
    border-radius: 20px;
    height: 460px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 300px;
  }

  .sm-landing .card-bg-2 {
    background-color: #0d6d3e;
    border-radius: 20px;
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 620px;
  }

  .sm-landing .work-elipse {
    background-color: #66d77f;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 330px;
    position: absolute;
    top: 202px;
    width: 300px;
  }

  .sm-landing .text-17 {
    color: #66d77f;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 220px;
  }

  .sm-landing .text-wrapper-6 {
    color: #66d77f;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .sm-landing .text-18 {
    color: #66d77f;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 220px;
  }

  .sm-landing .text-wrapper-7 {
    color: #66d77f;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .sm-landing .work-2 {
    height: 320px;
    left: 0;
    position: absolute;
    top: 140px;
    width: 300px;
  }

  .sm-landing .container-2 {
    height: 270px;
    left: 0;
    position: absolute;
    top: 1031px;
    width: 320px;
  }

  .sm-landing .element-wrapper {
    background-color: #f5f8f9;
    border-radius: 20px;
    height: 250px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 300px;
  }

  .sm-landing .element-2 {
    height: 190px;
    left: 10px;
    position: relative;
    top: 30px;
    width: 280px;
  }

  .sm-landing .text-19 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 300;
    height: 180px;
    left: 10px;
    letter-spacing: 0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 260px;
  }

  .sm-landing .text-wrapper-8 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  .sm-landing .frame-wrapper {
    all: unset;
    box-sizing: border-box;
    height: 70px;
    left: 58px;
    position: absolute;
    top: 1301px;
    width: 203px;
  }

  .sm-landing .frame-3 {
    background-color: #09305d;
    border-radius: 5px;
    height: 40px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 183px;
  }

  .sm-landing .text-20 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 2px;
    width: 163px;
  }

  .sm-landing .text-21 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }

  .sm-landing .text-wrapper-9 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }

  .sm-landing .features {
    background-color: #f5f8f9;
    height: 1472px;
    left: 0;
    position: absolute;
    top: 2591px;
    width: 320px;
  }

  .sm-landing .container-3 {
    height: 1412px;
    position: relative;
    top: 30px;
  }

  .sm-landing .title {
    height: 105px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .text-22 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 90px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 300px;
  }

  .sm-landing .row-2 {
    height: 1307px;
    left: 0;
    position: absolute;
    top: 105px;
    width: 320px;
  }

  .sm-landing .col-2 {
    height: 385px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .frame-wrapper-2 {
    height: 140px;
    left: 100px;
    position: absolute;
    top: 0;
    width: 120px;
  }

  .sm-landing .frame-4 {
    background-color: #036136;
    border-radius: 200px;
    height: 100px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 20px;
    width: 100px;
  }

  .sm-landing .overlap-group-3 {
    height: 100px;
    left: 20px;
    position: relative;
    top: -20px;
    width: 110px;
  }

  .sm-landing .img-3 {
    height: 60px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 60px;
  }

  .sm-landing .ellipse {
    background-color: #66d77f;
    border-radius: 50px;
    filter: blur(100px);
    height: 100px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .sm-landing .element-3 {
    height: 60px;
    left: 10px;
    position: absolute;
    top: 140px;
    width: 300px;
  }

  .sm-landing .text-23 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 20px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 280px;
  }

  .sm-landing .element-4 {
    height: 185px;
    left: 10px;
    position: absolute;
    top: 200px;
    width: 300px;
  }

  .sm-landing .text-24 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 175px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 280px;
  }

  .sm-landing .col-3 {
    height: 485px;
    left: 0;
    position: absolute;
    top: 411px;
    width: 320px;
  }

  .sm-landing .element-5 {
    height: 285px;
    left: 10px;
    position: absolute;
    top: 200px;
    width: 300px;
  }

  .sm-landing .text-25 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 275px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 280px;
  }

  .sm-landing .col-4 {
    height: 385px;
    left: 0;
    position: absolute;
    top: 922px;
    width: 320px;
  }

  .sm-landing .about {
    height: 865px;
    left: 0;
    position: absolute;
    top: 4063px;
    width: 320px;
  }

  .sm-landing .row-3 {
    height: 805px;
    position: relative;
    top: 30px;
  }

  .sm-landing .col-5 {
    height: 505px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .frame-5 {
    height: 465px;
    position: relative;
    top: 20px;
  }

  .sm-landing .text-26 {
    height: 60px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .text-27 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 45px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .element-6 {
    height: 335px;
    left: 10px;
    position: absolute;
    top: 60px;
    width: 300px;
  }

  .sm-landing .text-28 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 325px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .button-2 {
    all: unset;
    box-sizing: border-box;
    height: 70px;
    left: 10px;
    position: absolute;
    top: 395px;
    width: 181px;
  }

  .sm-landing .frame-6 {
    background-color: #09305d;
    border-radius: 5px;
    height: 40px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 161px;
  }

  .sm-landing .text-29 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 2px;
    width: 141px;
  }

  .sm-landing .about-img-wrapper {
    height: 300px;
    left: 0;
    position: absolute;
    top: 505px;
    width: 320px;
  }

  .sm-landing .about-img {
    height: 300px;
    left: 10px;
    position: relative;
    width: 300px;
  }

  .sm-landing .about-block {
    background-color: #036136;
    border-radius: 8.82px;
    height: 265px;
    left: 35px;
    overflow: hidden;
    position: absolute;
    top: 35px;
    width: 265px;
  }

  .sm-landing .about-img-2 {
    height: 265px;
    left: 0;
    position: absolute;
    top: 0;
    width: 265px;
  }

  .sm-landing .download {
    background-color: #f5f7f9;
    height: 455px;
    left: 0;
    position: absolute;
    top: 4928px;
    width: 320px;
  }

  .sm-landing .gorup-wrapper {
    height: 395px;
    position: relative;
    top: 30px;
  }

  .sm-landing .gorup {
    height: 395px;
    width: 320px;
  }

  .sm-landing .frame-7 {
    background-color: #ffffff;
    border-radius: 20px;
    height: 375px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 300px;
  }

  .sm-landing .text-30 {
    height: 35px;
    left: 20px;
    position: absolute;
    top: 20px;
    width: 260px;
  }

  .sm-landing .text-31 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 4px;
    width: 240px;
  }

  .sm-landing .text-32 {
    height: 125px;
    left: 20px;
    position: absolute;
    top: 55px;
    width: 260px;
  }

  .sm-landing .text-33 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 90px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    text-align: center;
    top: 19px;
    width: 240px;
  }

  .sm-landing .frame-8 {
    height: 175px;
    left: 20px;
    position: absolute;
    top: 180px;
    width: 260px;
  }

  .sm-landing .faq {
    height: 1486px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 5383px;
    width: 320px;
  }

  .sm-landing .overlap-3 {
    height: 1456px;
    left: -780px;
    position: relative;
    width: 1100px;
  }

  .sm-landing .container-4 {
    height: 1426px;
    left: 780px;
    position: absolute;
    top: 30px;
    width: 320px;
  }

  .sm-landing .accordion {
    height: 1075px;
    left: 0;
    position: absolute;
    top: 71px;
    width: 320px;
  }

  .sm-landing .group-3 {
    border-radius: 20px;
    height: 1055px;
    overflow: hidden;
    position: relative;
  }

  .sm-landing .accordion-2 {
    height: 125px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .sm-landing .frame-9 {
    background-color: #ffffff;
    border-radius: 20px 20px 0px 0px;
    height: 127px;
    left: 9px;
    overflow: hidden;
    position: relative;
    top: -1px;
    width: 302px;
  }

  .sm-landing .text-34 {
    height: 85px;
    left: 20px;
    position: absolute;
    top: 20px;
    width: 220px;
  }

  .sm-landing .text-35 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 75px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    width: 200px;
  }

  .sm-landing .icon-3 {
    height: 30px;
    left: 250px;
    position: absolute;
    top: 48px;
    width: 30px;
  }

  .sm-landing .accordion-3 {
    height: 125px;
    left: 0;
    position: absolute;
    top: 125px;
    width: 320px;
  }

  .sm-landing .frame-10 {
    background-color: #ffffff;
    height: 127px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 302px;
  }

  .sm-landing .accordion-4 {
    height: 430px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 320px;
  }

  .sm-landing .frame-11 {
    background-color: #ffffff;
    height: 432px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 302px;
  }

  .sm-landing .text-36 {
    height: 410px;
    left: 20px;
    position: relative;
    top: 10px;
    width: 260px;
  }

  .sm-landing .text-37 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 400px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 240px;
  }

  .sm-landing .accordion-title {
    height: 125px;
    left: 0;
    position: absolute;
    top: 680px;
    width: 320px;
  }

  .sm-landing .accordion-title-2 {
    height: 125px;
    left: 0;
    position: absolute;
    top: 805px;
    width: 320px;
  }

  .sm-landing .accordion-title-3 {
    height: 125px;
    left: 0;
    position: absolute;
    top: 930px;
    width: 320px;
  }

  .sm-landing .frame-12 {
    background-color: #ffffff;
    border-radius: 0px 0px 20px 20px;
    height: 127px;
    left: 9px;
    overflow: hidden;
    position: relative;
    top: -1px;
    width: 302px;
  }

  .sm-landing .cta {
    height: 280px;
    left: 0;
    position: absolute;
    top: 1146px;
    width: 320px;
  }

  .sm-landing .container-5 {
    background-color: #09305d;
    border-radius: 20px;
    height: 240px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 20px;
    width: 300px;
  }

  .sm-landing .faw-elipse {
    background-color: #669bd7;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 330px;
    position: absolute;
    top: 152px;
    width: 300px;
  }

  .sm-landing .text-38 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 300px;
  }

  .sm-landing .text-39 {
    color: #547dbf;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    height: 90px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 280px;
  }

  .sm-landing .text-wrapper-10 {
    color: #547dbf;
    font-family: "Ubuntu", Helvetica;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 45px;
  }

  .sm-landing .button-3 {
    all: unset;
    box-sizing: border-box;
    height: 70px;
    left: 76px;
    position: absolute;
    top: 140px;
    width: 148px;
  }

  .sm-landing .frame-13 {
    background-color: #ffffff;
    border-radius: 5px;
    height: 40px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 128px;
  }

  .sm-landing .text-40 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 2px;
    width: 108px;
  }

  .sm-landing .text-41 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }

  .sm-landing .text-wrapper-11 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }

  .sm-landing .faq-star {
    height: 99px;
    left: 819px;
    position: absolute;
    top: 36px;
    width: 95px;
  }

  .sm-landing .footer {
    background-color: #f5f8f9;
    height: 1181px;
    left: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 6869px;
    width: 320px;
  }

  .sm-landing .container-6 {
    height: 1136px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 300px;
  }

  .sm-landing .group-4 {
    height: 155px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .logo {
    height: 35px;
    left: 75px;
    position: absolute;
    top: 20px;
    width: 150px;
  }

  .sm-landing .frame-14 {
    height: 70px;
    left: 45px;
    position: absolute;
    top: 65px;
    width: 210px;
  }

  .sm-landing .btn-wrapper {
    height: 70px;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 70px;
  }

  .sm-landing .icon-wrapper {
    height: 50px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 50px;
  }

  .sm-landing .frame-15 {
    height: 70px;
    left: 70px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 70px;
  }

  .sm-landing .frame-16 {
    height: 70px;
    left: 140px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 70px;
  }

  .sm-landing .row-4 {
    height: 841px;
    left: 0;
    position: absolute;
    top: 155px;
    width: 300px;
  }

  .sm-landing .col-6 {
    height: 306px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 300px;
  }

  .sm-landing .frame-17 {
    height: 196px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .item {
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .icon-4 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 4px;
    width: 30px;
  }

  .sm-landing .button-4 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 247px;
  }

  .sm-landing .frame-18 {
    height: 35px;
    position: relative;
    top: 1px;
  }

  .sm-landing .text-42 {
    height: 35px;
    position: relative;
    width: 247px;
  }

  .sm-landing .text-43 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 4px;
    white-space: nowrap;
  }

  .sm-landing .frame-19 {
    height: 47px;
    left: 0;
    position: absolute;
    top: 47px;
    width: 300px;
  }

  .sm-landing .button-5 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 145px;
  }

  .sm-landing .text-44 {
    height: 35px;
    position: relative;
    width: 145px;
  }

  .sm-landing .frame-20 {
    height: 72px;
    left: 0;
    position: absolute;
    top: 94px;
    width: 300px;
  }

  .sm-landing .icon-5 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 16px;
    width: 30px;
  }

  .sm-landing .button-6 {
    height: 62px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 247px;
  }

  .sm-landing .frame-21 {
    height: 60px;
    position: relative;
    top: 1px;
  }

  .sm-landing .text-45 {
    height: 60px;
    position: relative;
    width: 247px;
  }

  .sm-landing .text-46 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    width: 227px;
  }

  .sm-landing .element-7 {
    height: 110px;
    left: 0;
    position: absolute;
    top: 196px;
    width: 300px;
  }

  .sm-landing .text-47 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 300;
    height: 100px;
    left: 10px;
    letter-spacing: 0.28px;
    line-height: 20px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .row-wrapper {
    height: 490px;
    left: 0;
    position: absolute;
    top: 321px;
    width: 300px;
  }

  .sm-landing .row-5 {
    height: 490px;
    position: relative;
  }

  .sm-landing .col-7 {
    height: 188px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .text-48 {
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .text-49 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 20px;
    left: 10px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: -1px;
    width: 280px;
  }

  .sm-landing .text-wrapper-12 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
  }

  .sm-landing .button-7 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 66px;
  }

  .sm-landing .text-50 {
    height: 35px;
    position: relative;
    width: 66px;
  }

  .sm-landing .button-8 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 67px;
    width: 84px;
  }

  .sm-landing .text-51 {
    height: 35px;
    position: relative;
    width: 84px;
  }

  .sm-landing .button-9 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 104px;
    width: 85px;
  }

  .sm-landing .text-52 {
    height: 35px;
    position: relative;
    width: 85px;
  }

  .sm-landing .button-10 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 141px;
    width: 111px;
  }

  .sm-landing .text-53 {
    height: 35px;
    position: relative;
    width: 111px;
  }

  .sm-landing .col-8 {
    height: 188px;
    left: 0;
    position: absolute;
    top: 188px;
    width: 300px;
  }

  .sm-landing .button-11 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 92px;
  }

  .sm-landing .text-54 {
    height: 35px;
    position: relative;
    width: 92px;
  }

  .sm-landing .button-12 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 67px;
    width: 92px;
  }

  .sm-landing .button-13 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 104px;
    width: 108px;
  }

  .sm-landing .text-55 {
    height: 35px;
    position: relative;
    width: 108px;
  }

  .sm-landing .button-14 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 141px;
    width: 125px;
  }

  .sm-landing .text-56 {
    height: 35px;
    position: relative;
    width: 125px;
  }

  .sm-landing .col-9 {
    height: 114px;
    left: 0;
    position: absolute;
    top: 376px;
    width: 300px;
  }

  .sm-landing .button-15 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 113px;
  }

  .sm-landing .text-57 {
    height: 35px;
    position: relative;
    width: 113px;
  }

  .sm-landing .button-16 {
    all: unset;
    box-sizing: border-box;
    height: 37px;
    left: 0;
    position: absolute;
    top: 67px;
    width: 97px;
  }

  .sm-landing .text-58 {
    height: 35px;
    position: relative;
    width: 97px;
  }

  .sm-landing .copyright {
    height: 140px;
    left: 0;
    position: absolute;
    top: 996px;
    width: 300px;
  }

  .sm-landing .text-59 {
    height: 110px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .sm-landing .text-60 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 300;
    height: 100px;
    left: 10px;
    letter-spacing: 0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 280px;
  }

  .sm-landing .text-61 {
    height: 30px;
    left: 0;
    position: absolute;
    top: 110px;
    width: 300px;
  }

  .sm-landing .text-62 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 300;
    height: 20px;
    left: 10px;
    letter-spacing: 0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 280px;
  }

  .sm-landing .header {
    background-color: transparent;
    height: 90px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 320px;
  }

  .sm-landing .container-7 {
    -webkit-backdrop-filter: blur(25px) brightness(100%);
    backdrop-filter: blur(25px) brightness(100%);
    background-color: #ffffffbf;
    border-radius: 20px;
    height: 70px;
    position: relative;
    top: 10px;
    width: 320px;
  }

  .sm-landing .logo-2 {
    height: 35px;
    left: 85px;
    position: absolute;
    top: 18px;
    width: 150px;
  }

  .sm-landing .button-17 {
    height: 70px;
    left: 250px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 70px;
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  .md-landing {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .md-landing .div-3 {
    background-color: #ffffff;
    border: 1px none;
    height: 4763px;
    overflow: hidden;
    position: relative;
    width: 768px;
  }

  .md-landing .hero-2 {
    height: 715px;
    left: 0;
    position: absolute;
    top: 105px;
    width: 768px;
  }

  .md-landing .row-6 {
    height: 380px;
    left: 0;
    position: absolute;
    top: 0;
    width: 768px;
  }

  .md-landing .hero-img-wrapper {
    height: 380px;
    left: 24px;
    position: absolute;
    top: 0;
    width: 360px;
  }

  .md-landing .hero-img-2 {
    height: 360px;
    position: relative;
    top: 10px;
  }

  .md-landing .overlap-group-4 {
    height: 362px;
    position: relative;
    width: 360px;
  }

  .md-landing .hero-elipse-wrapper {
    background-color: #036136;
    border-radius: 20px;
    height: 320px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 42px;
    width: 320px;
  }

  .md-landing .div-4 {
    background-color: #66d77f;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 300px;
  }

  .md-landing .hero-img-3 {
    height: 320px;
    left: 40px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .md-landing .col-10 {
    height: 315px;
    left: 384px;
    position: absolute;
    top: 32px;
    width: 360px;
  }

  .md-landing .group-5 {
    height: 315px;
    position: relative;
  }

  .md-landing .text-63 {
    height: 200px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 340px;
  }

  .md-landing .text-64 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    height: 180px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    top: -1px;
    width: 320px;
  }

  .md-landing .text-wrapper-13 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 45px;
  }

  .md-landing .text-65 {
    height: 115px;
    left: 10px;
    position: absolute;
    top: 200px;
    width: 340px;
  }

  .md-landing .text-66 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 100px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 320px;
  }

  .md-landing .text-wrapper-14 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
  }

  .md-landing .frame-22 {
    height: 335px;
    left: 0;
    position: absolute;
    top: 380px;
    width: 768px;
  }

  .md-landing .frame-23 {
    height: 175px;
    left: 10px;
    position: absolute;
    top: 30px;
    width: 249px;
  }

  .md-landing .text-67 {
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 249px;
  }

  .md-landing .img-wrapper {
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .md-landing .icon-6 {
    height: 20px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
  }

  .md-landing .element-8 {
    height: 60px;
    left: 30px;
    position: absolute;
    top: 0;
    width: 219px;
  }

  .md-landing .text-68 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 22px;
    font-weight: 700;
    height: 50px;
    left: 5px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 204px;
  }

  .md-landing .text-wrapper-15 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 25px;
  }

  .md-landing .text-69 {
    height: 115px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 249px;
  }

  .md-landing .text-70 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 100px;
    left: 35px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 204px;
  }

  .md-landing .frame-24 {
    height: 275px;
    left: 259px;
    position: absolute;
    top: 30px;
    width: 249px;
  }

  .md-landing .text-71 {
    height: 35px;
    left: 0;
    position: absolute;
    top: 0;
    width: 249px;
  }

  .md-landing .element-9 {
    height: 35px;
    left: 30px;
    position: absolute;
    top: 0;
    width: 219px;
  }

  .md-landing .text-72 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 22px;
    font-weight: 700;
    height: 25px;
    left: 5px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 204px;
  }

  .md-landing .text-73 {
    height: 240px;
    left: 0;
    position: absolute;
    top: 35px;
    width: 249px;
  }

  .md-landing .text-74 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 225px;
    left: 35px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 204px;
  }

  .md-landing .frame-25 {
    height: 150px;
    left: 509px;
    position: absolute;
    top: 30px;
    width: 249px;
  }

  .md-landing .text-75 {
    height: 115px;
    left: 0;
    position: absolute;
    top: 35px;
    width: 249px;
  }

  .md-landing .container-wrapper {
    background-color: transparent;
    height: 105px;
    left: 0;
    position: absolute;
    top: 0;
    width: 769px;
  }

  .md-landing .container-8 {
    -webkit-backdrop-filter: blur(25px) brightness(100%);
    backdrop-filter: blur(25px) brightness(100%);
    background-color: #ffffffbf;
    border-radius: 20px;
    height: 65px;
    position: relative;
    top: 20px;
    width: 769px;
  }

  .md-landing .logo-3 {
    height: 46px;
    left: 20px;
    position: absolute;
    top: 9px;
    width: 200px;
  }

  .md-landing .frame-26 {
    height: 50px;
    left: 249px;
    position: absolute;
    top: 8px;
    width: 270px;
  }

  .md-landing .button-18 {
    all: unset;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 69px;
  }

  .md-landing .frame-27 {
    height: 50px;
  }

  .md-landing .text-76 {
    height: 35px;
    position: relative;
    top: 8px;
    width: 69px;
  }

  .md-landing .text-77 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }

  .md-landing .text-wrapper-16 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
  }

  .md-landing .frame-28 {
    height: 50px;
    left: 69px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 78px;
  }

  .md-landing .frame-29 {
    height: 50px;
    position: relative;
  }

  .md-landing .text-78 {
    height: 35px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 48px;
  }

  .md-landing .text-79 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 4px;
    white-space: nowrap;
  }

  .md-landing .icon-7 {
    height: 30px;
    left: 48px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .md-landing .frame-30 {
    height: 50px;
    left: 147px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 123px;
  }

  .md-landing .text-80 {
    height: 35px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 93px;
  }

  .md-landing .icon-8 {
    height: 30px;
    left: 93px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .md-landing .frame-31 {
    height: 65px;
    left: 548px;
    position: absolute;
    top: 0;
    width: 201px;
  }

  .md-landing .frame-32 {
    height: 35px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 76px;
  }

  .md-landing .frame-33 {
    height: 35px;
  }

  .md-landing .text-81 {
    height: 35px;
    position: relative;
    width: 76px;
  }

  .md-landing .button-19 {
    all: unset;
    box-sizing: border-box;
    height: 65px;
    left: 76px;
    position: absolute;
    top: 0;
    width: 125px;
  }

  .md-landing .frame-34 {
    background-color: #09305d;
    border-radius: 5px;
    height: 45px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 105px;
  }

  .md-landing .text-82 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 85px;
  }

  .md-landing .text-83 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }

  .md-landing .text-wrapper-17 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
  }

  .md-landing .work-3 {
    height: 675px;
    left: 0;
    position: absolute;
    top: 820px;
    width: 768px;
  }

  .md-landing .overlap-4 {
    background-image: url(https://cdn.animaapp.com/projects/65df51ced8a7585f62fd1159/releases/65e3ebe45171c35e90624147/img/work-decoartion-2@2x.png);
    background-size: 100% 100%;
    height: 895px;
  }

  .md-landing .container-9 {
    height: 615px;
    position: relative;
    top: 30px;
    width: 768px;
  }

  .md-landing .text-84 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 768px;
  }

  .md-landing .text-85 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 26px;
    font-weight: 700;
    height: 30px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 748px;
  }

  .md-landing .text-wrapper-18 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }

  .md-landing .frame-35 {
    height: 360px;
    left: 0;
    position: absolute;
    top: 50px;
    width: 768px;
  }

  .md-landing .card-3 {
    height: 360px;
    left: 24px;
    position: absolute;
    top: 0;
    width: 360px;
  }

  .md-landing .group-6 {
    background-color: #09305d;
    border-radius: 20px;
    height: 340px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 340px;
  }

  .md-landing .overlap-group-5 {
    height: 382px;
    position: relative;
    width: 350px;
  }

  .md-landing .card-bg-3 {
    background-color: #09305d;
    border-radius: 20px;
    height: 340px;
    left: 0;
    position: absolute;
    top: 0;
    width: 340px;
  }

  .md-landing .schedule-elipse-2 {
    background-color: #669bd7;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 50px;
    position: absolute;
    top: 82px;
    width: 300px;
  }

  .md-landing .group-7 {
    height: 120px;
    left: 0;
    position: absolute;
    top: 20px;
    width: 340px;
  }

  .md-landing .text-86 {
    height: 40px;
    left: 30px;
    position: absolute;
    top: 0;
    width: 280px;
  }

  .md-landing .text-87 {
    color: #669bd7;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 260px;
  }

  .md-landing .text-wrapper-19 {
    color: #669bd7;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .md-landing .text-88 {
    height: 80px;
    left: 30px;
    position: absolute;
    top: 40px;
    width: 280px;
  }

  .md-landing .text-89 {
    color: #669bd7;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 260px;
  }

  .md-landing .text-wrapper-20 {
    color: #669bd7;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .md-landing .img-4 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 140px;
    width: 340px;
  }

  .md-landing .button-20 {
    height: 90px;
    left: 240px;
    opacity: 0.75;
    position: absolute;
    top: 240px;
    width: 90px;
  }

  .md-landing .btn-2 {
    height: 50px;
    left: 20px;
    position: relative;
    top: 20px;
    width: 50px;
  }

  .md-landing .icon-9 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .md-landing .card-4 {
    height: 360px;
    left: 384px;
    position: absolute;
    top: 0;
    width: 360px;
  }

  .md-landing .group-8 {
    background-color: #036136;
    border-radius: 20px;
    height: 340px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 340px;
  }

  .md-landing .card-bg-4 {
    background-color: #0d6d3e;
    border-radius: 20px;
    height: 340px;
    left: 0;
    position: absolute;
    top: 0;
    width: 340px;
  }

  .md-landing .work-elipse-2 {
    background-color: #66d77f;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 50px;
    position: absolute;
    top: 82px;
    width: 300px;
  }

  .md-landing .text-90 {
    color: #66d77f;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 260px;
  }

  .md-landing .text-wrapper-21 {
    color: #66d77f;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .md-landing .text-91 {
    color: #66d77f;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 260px;
  }

  .md-landing .text-wrapper-22 {
    color: #66d77f;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .md-landing .container-10 {
    height: 140px;
    left: 0;
    position: absolute;
    top: 410px;
    width: 768px;
  }

  .md-landing .group-9 {
    background-color: #f5f8f9;
    border-radius: 20px;
    height: 120px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 748px;
  }

  .md-landing .element-10 {
    height: 90px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 728px;
  }

  .md-landing .text-92 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 15px;
    font-weight: 300;
    height: 80px;
    left: 10px;
    letter-spacing: 0.3px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 708px;
  }

  .md-landing .text-wrapper-23 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }

  .md-landing .button-21 {
    all: unset;
    box-sizing: border-box;
    height: 65px;
    left: 278px;
    position: absolute;
    top: 550px;
    width: 212px;
  }

  .md-landing .frame-36 {
    background-color: #09305d;
    border-radius: 5px;
    height: 45px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 192px;
  }

  .md-landing .text-93 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 172px;
  }

  .md-landing .features-2 {
    background-color: #f5f8f9;
    height: 800px;
    left: 0;
    position: absolute;
    top: 1495px;
    width: 768px;
  }

  .md-landing .container-11 {
    height: 760px;
    left: 24px;
    position: relative;
    top: 20px;
    width: 720px;
  }

  .md-landing .title-2 {
    height: 65px;
    left: 0;
    position: absolute;
    top: 0;
    width: 720px;
  }

  .md-landing .text-94 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    height: 45px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 700px;
  }

  .md-landing .row-7 {
    height: 695px;
    left: 0;
    position: absolute;
    top: 65px;
    width: 720px;
  }

  .md-landing .col-11 {
    height: 495px;
    left: 0;
    position: absolute;
    top: 0;
    width: 233px;
  }

  .md-landing .frame-wrapper-3 {
    height: 140px;
    left: 0;
    position: absolute;
    top: 0;
    width: 120px;
  }

  .md-landing .frame-37 {
    background-color: #036136;
    border-radius: 200px;
    height: 100px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 20px;
    width: 100px;
  }

  .md-landing .overlap-group-6 {
    height: 100px;
    left: 20px;
    position: relative;
    top: -20px;
    width: 110px;
  }

  .md-landing .img-5 {
    height: 60px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 60px;
  }

  .md-landing .ellipse-2 {
    background-color: #66d77f;
    border-radius: 50px;
    filter: blur(100px);
    height: 100px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .md-landing .element-11 {
    height: 90px;
    left: 0;
    position: absolute;
    top: 140px;
    width: 233px;
  }

  .md-landing .text-95 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 22px;
    font-weight: 700;
    height: 75px;
    left: 10px;
    letter-spacing: 0;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 213px;
  }

  .md-landing .element-12 {
    height: 265px;
    left: 0;
    position: absolute;
    top: 230px;
    width: 233px;
  }

  .md-landing .text-96 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 250px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 213px;
  }

  .md-landing .col-12 {
    height: 695px;
    left: 243px;
    position: absolute;
    top: 0;
    width: 233px;
  }

  .md-landing .element-13 {
    height: 465px;
    left: 0;
    position: absolute;
    top: 230px;
    width: 233px;
  }

  .md-landing .text-97 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 450px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 213px;
  }

  .md-landing .col-13 {
    height: 470px;
    left: 487px;
    position: absolute;
    top: 0;
    width: 233px;
  }

  .md-landing .element-14 {
    height: 240px;
    left: 0;
    position: absolute;
    top: 230px;
    width: 233px;
  }

  .md-landing .text-98 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 225px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 213px;
  }

  .md-landing .about-2 {
    height: 505px;
    left: 0;
    position: absolute;
    top: 2295px;
    width: 768px;
  }

  .md-landing .row-8 {
    height: 445px;
    left: 14px;
    position: relative;
    top: 30px;
    width: 740px;
  }

  .md-landing .col-14 {
    height: 445px;
    left: 0;
    position: absolute;
    top: 0;
    width: 360px;
  }

  .md-landing .frame-38 {
    height: 445px;
    left: 10px;
    position: relative;
    width: 340px;
  }

  .md-landing .text-99 {
    height: 65px;
    left: 0;
    position: absolute;
    top: 0;
    width: 340px;
  }

  .md-landing .text-100 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    height: 45px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    top: -1px;
    width: 320px;
  }

  .md-landing .element-15 {
    height: 315px;
    left: 0;
    position: absolute;
    top: 65px;
    width: 340px;
  }

  .md-landing .text-101 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 300px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 320px;
  }

  .md-landing .button-22 {
    all: unset;
    box-sizing: border-box;
    height: 65px;
    left: 0;
    position: absolute;
    top: 380px;
    width: 188px;
  }

  .md-landing .frame-39 {
    background-color: #09305d;
    border-radius: 5px;
    height: 45px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 168px;
  }

  .md-landing .text-102 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 148px;
  }

  .md-landing .col-15 {
    height: 360px;
    left: 360px;
    position: absolute;
    top: 0;
    width: 380px;
  }

  .md-landing .about-img-3 {
    height: 360px;
    width: 360px;
  }

  .md-landing .overlap-group-7 {
    height: 360px;
    position: relative;
  }

  .md-landing .about-elipse-wrapper {
    background-color: #036136;
    border-radius: 20px;
    height: 320px;
    left: 40px;
    overflow: hidden;
    position: absolute;
    top: 40px;
    width: 320px;
  }

  .md-landing .about-img-4 {
    height: 320px;
    left: 0;
    position: absolute;
    top: 0;
    width: 320px;
  }

  .md-landing .download-2 {
    background-color: #f5f8f9;
    height: 380px;
    left: 0;
    position: absolute;
    top: 2800px;
    width: 760px;
  }

  .md-landing .container-12 {
    height: 320px;
    position: relative;
    top: 30px;
  }

  .md-landing .gorup-2 {
    height: 320px;
    left: 60px;
    position: relative;
    width: 640px;
  }

  .md-landing .frame-40 {
    background-color: #ffffff;
    border-radius: 20px;
    height: 300px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 620px;
  }

  .md-landing .text-103 {
    height: 35px;
    left: 20px;
    position: absolute;
    top: 40px;
    width: 580px;
  }

  .md-landing .text-104 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: 4px;
    width: 560px;
  }

  .md-landing .frame-41 {
    height: 85px;
    left: 20px;
    position: absolute;
    top: 75px;
    width: 580px;
  }

  .md-landing .frame-42 {
    height: 85px;
  }

  .md-landing .text-105 {
    height: 85px;
    position: relative;
    width: 580px;
  }

  .md-landing .text-106 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    height: 45px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    text-align: center;
    top: 19px;
    width: 560px;
  }

  .md-landing .frame-43 {
    height: 100px;
    left: 20px;
    position: absolute;
    top: 160px;
    width: 580px;
  }

  .md-landing .faq-2 {
    height: 900px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 3180px;
    width: 768px;
  }

  .md-landing .overlap-5 {
    height: 1100px;
    position: relative;
    width: 776px;
  }

  .md-landing .faq-decoration {
    height: 1100px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 768px;
  }

  .md-landing .container-13 {
    height: 870px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 768px;
  }

  .md-landing .text-107 {
    height: 50px;
    left: 10px;
    position: absolute;
    top: 0;
    width: 748px;
  }

  .md-landing .text-108 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 26px;
    font-weight: 700;
    height: 30px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 728px;
  }

  .md-landing .accordion-5 {
    height: 580px;
    left: 10px;
    position: absolute;
    top: 50px;
    width: 748px;
  }

  .md-landing .group-10 {
    height: 560px;
    position: relative;
  }

  .md-landing .accordion-6 {
    height: 75px;
    left: 0;
    position: absolute;
    top: 0;
    width: 748px;
  }

  .md-landing .frame-44 {
    background-color: #ffffff;
    border-radius: 20px 20px 0px 0px;
    height: 77px;
    left: 9px;
    overflow: hidden;
    position: relative;
    top: -1px;
    width: 730px;
  }

  .md-landing .text-109 {
    height: 35px;
    left: 20px;
    position: absolute;
    top: 20px;
    width: 648px;
  }

  .md-landing .text-110 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    width: 628px;
  }

  .md-landing .icon-10 {
    height: 30px;
    left: 678px;
    position: absolute;
    top: 22px;
    width: 30px;
  }

  .md-landing .accordion-7 {
    height: 75px;
    left: 0;
    position: absolute;
    top: 75px;
    width: 748px;
  }

  .md-landing .frame-45 {
    background-color: #ffffff;
    height: 77px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 730px;
  }

  .md-landing .accordion-8 {
    height: 185px;
    left: 0;
    position: absolute;
    top: 150px;
    width: 748px;
  }

  .md-landing .frame-46 {
    background-color: #ffffff;
    height: 187px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 730px;
  }

  .md-landing .text-111 {
    height: 165px;
    left: 20px;
    position: relative;
    top: 10px;
    width: 688px;
  }

  .md-landing .text-112 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 150px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 668px;
  }

  .md-landing .accordion-9 {
    height: 75px;
    left: 0;
    position: absolute;
    top: 335px;
    width: 748px;
  }

  .md-landing .accordion-title-4 {
    height: 75px;
    left: 0;
    position: absolute;
    top: 410px;
    width: 748px;
  }

  .md-landing .accordion-title-5 {
    height: 75px;
    left: 0;
    position: absolute;
    top: 485px;
    width: 748px;
  }

  .md-landing .frame-47 {
    background-color: #ffffff;
    border-radius: 0px 0px 20px 20px;
    height: 77px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 730px;
  }

  .md-landing .cta-2 {
    height: 240px;
    left: 10px;
    position: absolute;
    top: 630px;
    width: 748px;
  }

  .md-landing .container-14 {
    background-color: #09305d;
    border-radius: 20px;
    height: 200px;
    overflow: hidden;
    position: relative;
    top: 20px;
  }

  .md-landing .overlap-6 {
    height: 416px;
    position: relative;
    top: 36px;
    width: 748px;
  }

  .md-landing .overlap-group-8 {
    height: 347px;
    left: 298px;
    position: absolute;
    top: 69px;
    width: 332px;
  }

  .md-landing .faw-elipse-2 {
    background-color: #669bd7;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 32px;
    position: absolute;
    top: 47px;
    width: 300px;
  }

  .md-landing .button-23 {
    all: unset;
    box-sizing: border-box;
    height: 65px;
    left: 0;
    position: absolute;
    top: 0;
    width: 153px;
  }

  .md-landing .frame-48 {
    background-color: #ffffff;
    border-radius: 5px;
    height: 45px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 133px;
  }

  .md-landing .text-113 {
    height: 35px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 113px;
  }

  .md-landing .overlap-7 {
    height: 99px;
    left: 0;
    position: absolute;
    top: 0;
    width: 748px;
  }

  .md-landing .text-114 {
    height: 65px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 748px;
  }

  .md-landing .text-115 {
    color: #547dbf;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    height: 45px;
    left: 10px;
    letter-spacing: 0;
    line-height: 45px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 728px;
  }

  .md-landing .text-wrapper-24 {
    color: #547dbf;
    font-family: "Ubuntu", Helvetica;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 45px;
  }

  .md-landing .faq-star-2 {
    height: 99px;
    left: 619px;
    position: absolute;
    top: 0;
    width: 95px;
  }

  .md-landing .footer-2 {
    background-color: #f5f8f9;
    height: 683px;
    left: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 4080px;
    width: 768px;
  }

  .md-landing .container-15 {
    height: 638px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 748px;
  }

  .md-landing .group-11 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 748px;
  }

  .md-landing .logo-4 {
    height: 64px;
    left: 10px;
    position: absolute;
    top: 18px;
    width: 280px;
  }

  .md-landing .frame-49 {
    height: 80px;
    left: 498px;
    position: absolute;
    top: 10px;
    width: 240px;
  }

  .md-landing .button-24 {
    height: 80px;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 80px;
  }

  .md-landing .btn-3 {
    height: 50px;
    left: 10px;
    position: relative;
    top: 15px;
    width: 60px;
  }

  .md-landing .icon-11 {
    height: 30px;
    left: 15px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .md-landing .frame-50 {
    height: 80px;
    left: 80px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 80px;
  }

  .md-landing .frame-51 {
    height: 80px;
    left: 160px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 80px;
  }

  .md-landing .row-9 {
    height: 458px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 748px;
  }

  .md-landing .col-16 {
    height: 190px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 748px;
  }

  .md-landing .frame-52 {
    height: 190px;
    left: 0;
    position: absolute;
    top: 0;
    width: 374px;
  }

  .md-landing .frame-53 {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 374px;
  }

  .md-landing .icon-12 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 2px;
    width: 30px;
  }

  .md-landing .button-25 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 261px;
  }

  .md-landing .text-116 {
    height: 35px;
    position: relative;
    width: 261px;
  }

  .md-landing .frame-54 {
    height: 45px;
    left: 0;
    position: absolute;
    top: 45px;
    width: 374px;
  }

  .md-landing .button-26 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 153px;
  }

  .md-landing .text-117 {
    height: 35px;
    position: relative;
    width: 153px;
  }

  .md-landing .frame-55 {
    height: 70px;
    left: 0;
    position: absolute;
    top: 90px;
    width: 374px;
  }

  .md-landing .icon-13 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 15px;
    width: 30px;
  }

  .md-landing .button-27 {
    height: 60px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 324px;
  }

  .md-landing .frame-56 {
    height: 60px;
    width: 261px;
  }

  .md-landing .text-118 {
    height: 60px;
    position: relative;
  }

  .md-landing .text-119 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 17px;
    font-weight: 300;
    height: 50px;
    left: 10px;
    letter-spacing: 0.34px;
    line-height: 25px;
    position: absolute;
    top: 4px;
    width: 241px;
  }

  .md-landing .element-16 {
    height: 90px;
    left: 374px;
    position: absolute;
    top: 0;
    width: 374px;
  }

  .md-landing .text-120 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 15px;
    font-weight: 300;
    height: 80px;
    left: 10px;
    letter-spacing: 0.3px;
    line-height: 20px;
    position: absolute;
    top: -1px;
    width: 354px;
  }

  .md-landing .col-17 {
    height: 208px;
    left: 14px;
    position: absolute;
    top: 220px;
    width: 720px;
  }

  .md-landing .row-10 {
    height: 175px;
    position: relative;
    top: 16px;
  }

  .md-landing .col-18 {
    height: 175px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .md-landing .text-121 {
    height: 35px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .md-landing .text-122 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 17px;
    font-weight: 700;
    height: 20px;
    left: 10px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: -1px;
    width: 220px;
  }

  .md-landing .text-wrapper-25 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
  }

  .md-landing .button-28 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 35px;
    width: 69px;
  }

  .md-landing .text-123 {
    height: 35px;
    position: relative;
    width: 69px;
  }

  .md-landing .button-29 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 70px;
    width: 88px;
  }

  .md-landing .text-124 {
    height: 35px;
    position: relative;
    width: 88px;
  }

  .md-landing .button-30 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 105px;
    width: 89px;
  }

  .md-landing .text-125 {
    height: 35px;
    position: relative;
    width: 89px;
  }

  .md-landing .button-31 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 140px;
    width: 117px;
  }

  .md-landing .text-126 {
    height: 35px;
    position: relative;
    width: 117px;
  }

  .md-landing .col-19 {
    height: 175px;
    left: 240px;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .md-landing .button-32 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 35px;
    width: 97px;
  }

  .md-landing .text-127 {
    height: 35px;
    position: relative;
    width: 97px;
  }

  .md-landing .button-33 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 70px;
    width: 97px;
  }

  .md-landing .button-34 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 105px;
    width: 113px;
  }

  .md-landing .text-128 {
    height: 35px;
    position: relative;
    width: 113px;
  }

  .md-landing .button-35 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 140px;
    width: 131px;
  }

  .md-landing .text-129 {
    height: 35px;
    position: relative;
    width: 131px;
  }

  .md-landing .col-20 {
    height: 105px;
    left: 480px;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .md-landing .button-36 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 35px;
    width: 118px;
  }

  .md-landing .text-130 {
    height: 35px;
    position: relative;
    width: 118px;
  }

  .md-landing .button-37 {
    all: unset;
    box-sizing: border-box;
    height: 35px;
    left: 0;
    position: absolute;
    top: 70px;
    width: 102px;
  }

  .md-landing .text-131 {
    height: 35px;
    position: relative;
    width: 102px;
  }

  .md-landing .copyright-2 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 558px;
    width: 748px;
  }

  .md-landing .text-132 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 748px;
  }

  .md-landing .text-133 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 15px;
    font-weight: 300;
    height: 40px;
    left: 10px;
    letter-spacing: 0.3px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 728px;
  }

  .md-landing .text-134 {
    height: 30px;
    left: 0;
    position: absolute;
    top: 50px;
    width: 748px;
  }

  .md-landing .text-135 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 15px;
    font-weight: 300;
    height: 20px;
    left: 10px;
    letter-spacing: 0.3px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 728px;
  }
}

@media (min-width: 1440px) {
  .lg-landing {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .lg-landing .div-5 {
    background-color: #ffffff;
    border: 1px none;
    height: 5570px;
    position: relative;
    width: 1440px;
  }

  .lg-landing .hero-3 {
    height: 720px;
    left: 0;
    position: absolute;
    top: 3rem;
    width: 1440px;
  }

  .lg-landing .row-11 {
    height: 720px;
    position: relative;
  }

  .lg-landing .col-21 {
    height: 720px;
    left: 0;
    position: absolute;
    top: 0;
    width: 720px;
  }

  .lg-landing .hero-img-4 {
    height: 680px;
    left: 20px;
    position: relative;
    top: 20px;
    width: 680px;
  }

  .lg-landing .overlap-group-9 {
    height: 680px;
    position: relative;
  }

  .lg-landing .hero-block-2 {
    background-color: #036136;
    border-radius: 20px;
    height: 600px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 80px;
    width: 600px;
  }

  .lg-landing .div-6 {
    background-color: #66d77f;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 150px;
    position: relative;
    top: 150px;
    width: 300px;
  }

  .lg-landing .hero-img-5 {
    height: 600px;
    left: 80px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 600px;
  }

  .lg-landing .col-22 {
    height: 720px;
    left: 720px;
    position: absolute;
    top: 0;
    width: 720px;
  }

  .lg-landing .group-12 {
    height: 690px;
    left: 120px;
    position: relative;
    top: 15px;
    width: 480px;
  }

  .lg-landing .text-136 {
    height: 130px;
    left: 0;
    position: absolute;
    top: 0;
    width: 480px;
  }

  .lg-landing .text-137 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 100px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .text-wrapper-26 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .lg-landing .text-138 {
    height: 110px;
    left: 0;
    position: absolute;
    top: 130px;
    width: 480px;
  }

  .lg-landing .text-139 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 90px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .text-wrapper-27 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .lg-landing .text-140 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 240px;
    width: 480px;
  }

  .lg-landing .frame-57 {
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }

  .lg-landing .icon-14 {
    height: 20px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
  }

  .lg-landing .element-17 {
    height: 50px;
    left: 30px;
    position: absolute;
    top: 0;
    width: 450px;
  }

  .lg-landing .text-141 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 24px;
    font-weight: 700;
    height: 30px;
    left: 5px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 435px;
  }

  .lg-landing .text-wrapper-28 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
  }

  .lg-landing .text-142 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 290px;
    width: 480px;
  }

  .lg-landing .text-143 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 60px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .text-144 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 370px;
    width: 480px;
  }

  .lg-landing .text-145 {
    height: 140px;
    left: 0;
    position: absolute;
    top: 420px;
    width: 480px;
  }

  .lg-landing .text-146 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 120px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .text-147 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 560px;
    width: 480px;
  }

  .lg-landing .text-148 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 610px;
    width: 480px;
  }

  .lg-landing .work-4 {
    height: 895px;
    left: 0;
    position: absolute;
    top: 930px;
    width: 1440px;
  }

  .lg-landing .overlap-8 {
    height: 1100px;
    left: -12px;
    position: relative;
    width: 1372px;
  }

  .lg-landing .work-decoartion {
    height: 1100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1100px;
  }

  .lg-landing .container-16 {
    height: 775px;
    left: 92px;
    position: absolute;
    top: 60px;
    width: 1280px;
  }

  .lg-landing .text-149 {
    height: 65px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1280px;
  }

  .lg-landing .text-150 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 35px;
    left: 10px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 1260px;
  }

  .lg-landing .text-wrapper-29 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 35px;
  }

  .lg-landing .frame-58 {
    height: 480px;
    left: 0;
    position: absolute;
    top: 65px;
    width: 1280px;
  }

  .lg-landing .card-5 {
    height: 480px;
    left: 0;
    position: absolute;
    top: 0;
    width: 640px;
  }

  .lg-landing .group-13 {
    background-color: #09305d;
    border-radius: 20px;
    height: 460px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 620px;
  }

  .lg-landing .overlap-group-10 {
    height: 502px;
    position: relative;
    width: 630px;
  }

  .lg-landing .card-bg-5 {
    background-color: #09305d;
    border-radius: 20px;
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 620px;
  }

  .lg-landing .schedule-elipse-3 {
    background-color: #669bd7;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 330px;
    position: absolute;
    top: 202px;
    width: 300px;
  }

  .lg-landing .group-14 {
    height: 120px;
    left: 0;
    position: absolute;
    top: 20px;
    width: 620px;
  }

  .lg-landing .text-151 {
    height: 40px;
    left: 30px;
    position: absolute;
    top: 0;
    width: 560px;
  }

  .lg-landing .text-152 {
    color: #669bd7;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 540px;
  }

  .lg-landing .text-wrapper-30 {
    color: #669bd7;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .lg-landing .text-153 {
    height: 80px;
    left: 30px;
    position: absolute;
    top: 40px;
    width: 560px;
  }

  .lg-landing .text-154 {
    color: #669bd7;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 540px;
  }

  .lg-landing .text-wrapper-31 {
    color: #669bd7;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .lg-landing .img-6 {
    height: 320px;
    left: 0;
    position: absolute;
    top: 140px;
    width: 480px;
  }

  .lg-landing .button-38 {
    height: 90px;
    left: 520px;
    opacity: 0.75;
    position: absolute;
    top: 360px;
    width: 90px;
  }

  .lg-landing .btn-4 {
    height: 50px;
    left: 20px;
    position: relative;
    top: 20px;
    width: 50px;
  }

  .lg-landing .icon-15 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .lg-landing .card-6 {
    height: 480px;
    left: 640px;
    position: absolute;
    top: 0;
    width: 640px;
  }

  .lg-landing .group-15 {
    background-color: #036136;
    border-radius: 20px;
    height: 460px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 620px;
  }

  .lg-landing .card-bg-6 {
    background-color: #0d6d3e;
    border-radius: 20px;
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 620px;
  }

  .lg-landing .work-elipse-3 {
    background-color: #66d77f;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 330px;
    position: absolute;
    top: 202px;
    width: 300px;
  }

  .lg-landing .text-155 {
    color: #66d77f;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 540px;
  }

  .lg-landing .text-wrapper-32 {
    color: #66d77f;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .lg-landing .text-156 {
    color: #66d77f;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 540px;
  }

  .lg-landing .text-wrapper-33 {
    color: #66d77f;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .lg-landing .container-17 {
    height: 140px;
    left: 0;
    position: absolute;
    top: 545px;
    width: 1280px;
  }

  .lg-landing .group-16 {
    background-color: #f5f8f9;
    border-radius: 20px;
    height: 120px;
    left: 10px;
    position: relative;
    top: 10px;
    width: 1260px;
  }

  .lg-landing .element-18 {
    height: 60px;
    left: 20px;
    position: relative;
    top: 30px;
    width: 1220px;
  }

  .lg-landing .text-157 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 1200px;
  }

  .lg-landing .text-wrapper-34 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }

  .lg-landing .button-39 {
    all: unset;
    box-sizing: border-box;
    height: 90px;
    left: 530px;
    position: absolute;
    top: 685px;
    width: 220px;
  }

  .lg-landing .frame-59 {
    background-color: #09305d;
    border-radius: 5px;
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 200px;
  }

  .lg-landing .text-158 {
    height: 40px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 180px;
  }

  .lg-landing .text-159 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }

  .lg-landing .text-wrapper-35 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .lg-landing .features-3 {
    background-color: #f5f8f9;
    height: 740px;
    left: 0;
    position: absolute;
    top: 1825px;
    width: 1440px;
  }

  .lg-landing .container-18 {
    height: 620px;
    left: 80px;
    position: relative;
    top: 60px;
    width: 1280px;
  }

  .lg-landing .title-3 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1280px;
  }

  .lg-landing .text-160 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 1260px;
  }

  .lg-landing .row-12 {
    height: 540px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 1280px;
  }

  .lg-landing .col-23 {
    height: 420px;
    left: 0;
    position: absolute;
    top: 0;
    width: 420px;
  }

  .lg-landing .frame-wrapper-4 {
    height: 170px;
    left: 0;
    position: absolute;
    top: 0;
    width: 150px;
  }

  .lg-landing .frame-60 {
    background-color: #036136;
    border-radius: 200px;
    height: 130px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 20px;
    width: 130px;
  }

  .lg-landing .overlap-group-11 {
    height: 115px;
    left: 20px;
    position: relative;
    top: -5px;
    width: 125px;
  }

  .lg-landing .img-7 {
    height: 90px;
    left: 0;
    position: absolute;
    top: 25px;
    width: 90px;
  }

  .lg-landing .ellipse-3 {
    background-color: #66d77f;
    border-radius: 50px;
    filter: blur(100px);
    height: 100px;
    left: 25px;
    position: absolute;
    top: 0;
    width: 100px;
  }

  .lg-landing .element-19 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 170px;
    width: 420px;
  }

  .lg-landing .text-161 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 24px;
    font-weight: 700;
    height: 60px;
    left: 10px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 400px;
  }

  .lg-landing .element-20 {
    height: 170px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 420px;
  }

  .lg-landing .text-162 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 150px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 400px;
  }

  .lg-landing .col-24 {
    height: 540px;
    left: 440px;
    position: absolute;
    top: 0;
    width: 420px;
  }

  .lg-landing .element-21 {
    height: 290px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 420px;
  }

  .lg-landing .text-163 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 270px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 400px;
  }

  .lg-landing .col-25 {
    height: 420px;
    left: 880px;
    position: absolute;
    top: 0;
    width: 420px;
  }

  .lg-landing .about-3 {
    height: 840px;
    left: 0;
    position: absolute;
    top: 2565px;
    width: 1440px;
  }

  .lg-landing .row-13 {
    height: 720px;
    position: relative;
    top: 60px;
  }

  .lg-landing .frame-61 {
    height: 460px;
    left: 120px;
    position: relative;
    top: 130px;
    width: 480px;
  }

  .lg-landing .text-164 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 480px;
  }

  .lg-landing .text-165 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .element-22 {
    height: 290px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 480px;
  }

  .lg-landing .text-166 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 270px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .button-40 {
    all: unset;
    box-sizing: border-box;
    height: 90px;
    left: 0;
    position: absolute;
    top: 370px;
    width: 196px;
  }

  .lg-landing .frame-62 {
    background-color: #09305d;
    border-radius: 5px;
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 176px;
  }

  .lg-landing .text-167 {
    height: 40px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 156px;
  }

  .lg-landing .about-img-5 {
    height: 680px;
    width: 680px;
  }

  .lg-landing .about-block-2 {
    background-color: #036136;
    border-radius: 20px;
    height: 600px;
    left: 80px;
    overflow: hidden;
    position: absolute;
    top: 80px;
    width: 600px;
  }

  .lg-landing .about-img-6 {
    height: 600px;
    left: 0;
    position: absolute;
    top: 0;
    width: 600px;
  }

  .lg-landing .download-3 {
    background-color: #f5f8f9;
    height: 440px;
    left: 0;
    position: absolute;
    top: 3405px;
    width: 1440px;
  }

  .lg-landing .container-19 {
    height: 320px;
    left: 80px;
    position: relative;
    top: 60px;
    width: 1280px;
  }

  .lg-landing .gorup-3 {
    height: 320px;
    left: 320px;
    position: relative;
    width: 640px;
  }

  .lg-landing .frame-63 {
    background-color: #ffffff;
    border-radius: 20px;
    height: 300px;
    left: 10px;
    overflow: hidden;
    position: relative;
    top: 10px;
    width: 620px;
  }

  .lg-landing .text-168 {
    height: 40px;
    left: 20px;
    position: absolute;
    top: 30px;
    width: 580px;
  }

  .lg-landing .text-169 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: 4px;
    width: 560px;
  }

  .lg-landing .frame-64 {
    height: 100px;
    left: 20px;
    position: absolute;
    top: 70px;
    width: 580px;
  }

  .lg-landing .frame-65 {
    height: 100px;
  }

  .lg-landing .text-170 {
    height: 100px;
    position: relative;
    width: 580px;
  }

  .lg-landing .text-171 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: 19px;
    width: 560px;
  }

  .lg-landing .frame-66 {
    height: 100px;
    left: 20px;
    position: absolute;
    top: 170px;
    width: 580px;
  }

  .lg-landing .faq-3 {
    height: 1055px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 3845px;
    width: 1440px;
  }

  .lg-landing .overlap-9 {
    height: 1100px;
    left: 240px;
    position: relative;
    width: 1200px;
  }

  .lg-landing .faq-decoration-2 {
    height: 1100px;
    left: 100px;
    position: absolute;
    top: 0;
    width: 1100px;
  }

  .lg-landing .container-20 {
    height: 935px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 960px;
  }

  .lg-landing .text-172 {
    height: 65px;
    left: 0;
    position: absolute;
    top: 0;
    width: 960px;
  }

  .lg-landing .text-173 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 32px;
    font-weight: 700;
    height: 35px;
    left: 10px;
    letter-spacing: 0;
    line-height: 35px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 940px;
  }

  .lg-landing .accordion-10 {
    height: 610px;
    left: 0;
    position: absolute;
    top: 65px;
    width: 960px;
  }

  .lg-landing .group-17 {
    height: 590px;
    position: relative;
  }

  .lg-landing .accordion-11 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 960px;
  }

  .lg-landing .frame-67 {
    background-color: #ffffff;
    border-radius: 20px 20px 0px 0px;
    height: 82px;
    left: 9px;
    overflow: hidden;
    position: relative;
    top: -1px;
    width: 942px;
  }

  .lg-landing .text-174 {
    height: 40px;
    left: 40px;
    position: absolute;
    top: 20px;
    width: 820px;
  }

  .lg-landing .text-175 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    width: 800px;
  }

  .lg-landing .icon-16 {
    height: 30px;
    left: 870px;
    position: absolute;
    top: 25px;
    width: 30px;
  }

  .lg-landing .accordion-12 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 960px;
  }

  .lg-landing .frame-68 {
    background-color: #ffffff;
    height: 82px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 942px;
  }

  .lg-landing .accordion-13 {
    height: 190px;
    left: 0;
    position: absolute;
    top: 160px;
    width: 960px;
  }

  .lg-landing .frame-69 {
    background-color: #ffffff;
    height: 192px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 942px;
  }

  .lg-landing .text-176 {
    height: 170px;
    left: 40px;
    position: relative;
    top: 10px;
    width: 860px;
  }

  .lg-landing .text-177 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 150px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 840px;
  }

  .lg-landing .accordion-14 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 350px;
    width: 960px;
  }

  .lg-landing .accordion-title-6 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 430px;
    width: 960px;
  }

  .lg-landing .accordion-title-7 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 510px;
    width: 960px;
  }

  .lg-landing .frame-70 {
    background-color: #ffffff;
    border-radius: 0px 0px 20px 20px;
    height: 82px;
    left: 9px;
    position: relative;
    top: -1px;
    width: 942px;
  }

  .lg-landing .cta-3 {
    height: 260px;
    left: 0;
    position: absolute;
    top: 675px;
    width: 960px;
  }

  .lg-landing .container-21 {
    background-color: #09305d;
    border-radius: 20px;
    height: 220px;
    overflow: hidden;
    position: relative;
    top: 20px;
  }

  .lg-landing .overlap-10 {
    height: 416px;
    position: relative;
    top: 36px;
    width: 960px;
  }

  .lg-landing .overlap-11 {
    height: 352px;
    left: 330px;
    position: absolute;
    top: 64px;
    width: 300px;
  }

  .lg-landing .faw-elipse-3 {
    background-color: #669bd7;
    border-radius: 150px;
    filter: blur(300px);
    height: 300px;
    left: 0;
    position: absolute;
    top: 52px;
    width: 300px;
  }

  .lg-landing .button-41 {
    all: unset;
    box-sizing: border-box;
    height: 90px;
    left: 71px;
    position: absolute;
    top: 0;
    width: 158px;
  }

  .lg-landing .frame-71 {
    background-color: #ffffff;
    border-radius: 5px;
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 138px;
  }

  .lg-landing .text-178 {
    height: 40px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 118px;
  }

  .lg-landing .text-179 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }

  .lg-landing .text-wrapper-36 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .lg-landing .overlap-group-12 {
    height: 99px;
    left: 0;
    position: absolute;
    top: 0;
    width: 960px;
  }

  .lg-landing .text-180 {
    height: 60px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 960px;
  }

  .lg-landing .text-181 {
    color: #547dbf;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    height: 50px;
    left: 10px;
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 940px;
  }

  .lg-landing .text-wrapper-37 {
    color: #547dbf;
    font-family: "Ubuntu", Helvetica;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 50px;
  }

  .lg-landing .faq-star-3 {
    height: 99px;
    left: 819px;
    position: absolute;
    top: 0;
    width: 95px;
  }

  .lg-landing .footer-3 {
    background-color: #f5f8f9;
    height: 670px;
    left: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 4900px;
    width: 1440px;
  }

  .lg-landing .container-22 {
    height: 580px;
    left: 80px;
    position: relative;
    top: 30px;
    width: 1280px;
  }

  .lg-landing .group-18 {
    height: 130px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1280px;
  }

  .lg-landing .logo-5 {
    height: 64px;
    left: 0;
    position: absolute;
    top: 33px;
    width: 280px;
  }

  .lg-landing .frame-72 {
    height: 90px;
    left: 1010px;
    position: absolute;
    top: 20px;
    width: 270px;
  }

  .lg-landing .button-42 {
    height: 90px;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 90px;
  }

  .lg-landing .frame-73 {
    height: 90px;
    left: 90px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 90px;
  }

  .lg-landing .frame-74 {
    height: 90px;
    left: 180px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 90px;
  }

  .lg-landing .row-14 {
    height: 355px;
    left: 0;
    position: absolute;
    top: 130px;
    width: 1280px;
  }

  .lg-landing .col-26 {
    height: 265px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 480px;
  }

  .lg-landing .frame-75 {
    height: 180px;
    left: 0;
    position: absolute;
    top: 0;
    width: 480px;
  }

  .lg-landing .frame-76 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 325px;
  }

  .lg-landing .icon-17 {
    height: 30px;
    left: 10px;
    position: absolute;
    top: 5px;
    width: 30px;
  }

  .lg-landing .button-43 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 275px;
  }

  .lg-landing .frame-77 {
    height: 40px;
  }

  .lg-landing .text-182 {
    height: 40px;
    position: relative;
    width: 275px;
  }

  .lg-landing .text-183 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: 4px;
    white-space: nowrap;
  }

  .lg-landing .frame-78 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 50px;
    width: 211px;
  }

  .lg-landing .button-44 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 161px;
  }

  .lg-landing .text-184 {
    height: 40px;
    position: relative;
    width: 161px;
  }

  .lg-landing .frame-79 {
    height: 50px;
    left: 0;
    position: absolute;
    top: 100px;
    width: 395px;
  }

  .lg-landing .button-45 {
    height: 40px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 345px;
  }

  .lg-landing .text-185 {
    height: 40px;
    position: relative;
    width: 345px;
  }

  .lg-landing .element-23 {
    height: 85px;
    left: 0;
    position: absolute;
    top: 180px;
    width: 480px;
  }

  .lg-landing .text-186 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 75px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    top: -1px;
    width: 460px;
  }

  .lg-landing .col-27 {
    height: 208px;
    left: 560px;
    position: absolute;
    top: 30px;
    width: 720px;
  }

  .lg-landing .row-15 {
    height: 200px;
    position: relative;
    top: 4px;
  }

  .lg-landing .col-28 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .lg-landing .text-187 {
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .lg-landing .text-188 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 18px;
    font-weight: 700;
    height: 30px;
    left: 10px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: -1px;
    width: 220px;
  }

  .lg-landing .text-wrapper-38 {
    color: #09305d;
    font-family: "Ubuntu", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
  }

  .lg-landing .button-46 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 71px;
  }

  .lg-landing .text-189 {
    height: 40px;
    position: relative;
    width: 71px;
  }

  .lg-landing .button-47 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 92px;
  }

  .lg-landing .text-190 {
    height: 40px;
    position: relative;
    width: 92px;
  }

  .lg-landing .button-48 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 120px;
    width: 93px;
  }

  .lg-landing .text-191 {
    height: 40px;
    position: relative;
    width: 93px;
  }

  .lg-landing .button-49 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 160px;
    width: 122px;
  }

  .lg-landing .text-192 {
    height: 40px;
    position: relative;
    width: 122px;
  }

  .lg-landing .col-29 {
    height: 200px;
    left: 240px;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .lg-landing .button-50 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 101px;
  }

  .lg-landing .text-193 {
    height: 40px;
    position: relative;
    width: 101px;
  }

  .lg-landing .button-51 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 101px;
  }

  .lg-landing .button-52 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 120px;
    width: 118px;
  }

  .lg-landing .text-194 {
    height: 40px;
    position: relative;
    width: 118px;
  }

  .lg-landing .button-53 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 160px;
    width: 138px;
  }

  .lg-landing .text-195 {
    height: 40px;
    position: relative;
    width: 138px;
  }

  .lg-landing .col-30 {
    height: 120px;
    left: 480px;
    position: absolute;
    top: 0;
    width: 240px;
  }

  .lg-landing .button-54 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 124px;
  }

  .lg-landing .text-196 {
    height: 40px;
    position: relative;
    width: 124px;
  }

  .lg-landing .button-55 {
    all: unset;
    box-sizing: border-box;
    height: 40px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 107px;
  }

  .lg-landing .text-197 {
    height: 40px;
    position: relative;
    width: 107px;
  }

  .lg-landing .copyright-3 {
    height: 95px;
    left: 160px;
    position: absolute;
    top: 485px;
    width: 960px;
  }

  .lg-landing .text-198 {
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 960px;
  }

  .lg-landing .text-199 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 50px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 940px;
  }

  .lg-landing .text-200 {
    height: 35px;
    left: 0;
    position: absolute;
    top: 60px;
    width: 960px;
  }

  .lg-landing .text-201 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 300;
    height: 25px;
    left: 10px;
    letter-spacing: 0.32px;
    line-height: 25px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 940px;
  }

  .lg-landing .header-2 {
    background-color: transparent;
    height: 130px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 1440px;
  }

  .lg-landing .container-23 {
    -webkit-backdrop-filter: blur(25px) brightness(100%);
    backdrop-filter: blur(25px) brightness(100%);
    background-color: #ffffffbf;
    border-radius: 20px;
    height: 90px;
    left: 80px;
    position: relative;
    top: 20px;
    width: 1280px;
  }

  .lg-landing .logo-6 {
    height: 65px;
    left: 20px;
    position: absolute;
    top: 12px;
    width: 280px;
  }

  .lg-landing .frame-80 {
    height: 90px;
    left: 428px;
    position: absolute;
    top: 0;
    width: 459px;
  }

  .lg-landing .button-56 {
    all: unset;
    box-sizing: border-box;
    height: 90px;
    left: 30px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 111px;
  }

  .lg-landing .frame-81 {
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 91px;
  }

  .lg-landing .text-202 {
    height: 40px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 71px;
  }

  .lg-landing .text-203 {
    color: #09305d;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 10px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: 4px;
    white-space: nowrap;
  }

  .lg-landing .frame-82 {
    height: 90px;
    left: 141px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 120px;
  }

  .lg-landing .frame-83 {
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 100px;
  }

  .lg-landing .text-204 {
    height: 40px;
    left: 10px;
    position: absolute;
    top: 5px;
    width: 50px;
  }

  .lg-landing .icon-18 {
    height: 30px;
    left: 60px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .lg-landing .frame-84 {
    height: 90px;
    left: 261px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 168px;
  }

  .lg-landing .frame-85 {
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 148px;
  }

  .lg-landing .text-205 {
    height: 40px;
    left: 10px;
    position: absolute;
    top: 5px;
    width: 98px;
  }

  .lg-landing .icon-19 {
    height: 30px;
    left: 108px;
    position: absolute;
    top: 10px;
    width: 30px;
  }

  .lg-landing .frame-86 {
    height: 90px;
    left: 1015px;
    position: absolute;
    top: 0;
    width: 245px;
  }

  .lg-landing .frame-87 {
    height: 90px;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 120px;
  }

  .lg-landing .text-206 {
    height: 40px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 80px;
  }

  .lg-landing .button-57 {
    all: unset;
    box-sizing: border-box;
    height: 90px;
    left: 120px;
    position: absolute;
    top: 0;
    width: 125px;
  }

  .lg-landing .frame-88 {
    background-color: #09305d;
    border-radius: 5px;
    height: 50px;
    left: 10px;
    position: relative;
    top: 20px;
    width: 105px;
  }

  .lg-landing .text-207 {
    height: 40px;
    left: 10px;
    position: relative;
    top: 5px;
    width: 85px;
  }

  .lg-landing .text-208 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    left: 8px;
    letter-spacing: 0.36px;
    line-height: 30px;
    position: absolute;
    top: 4px;
    white-space: nowrap;
  }
}
