.dropdown-menu-adNucleus {
	margin-bottom: 1rem;
}

.nav-logo {
	width: 12rem;
	margin-left: 0;
}

div.dropdown-menu-adNucleus.nav-item.dropdown {
	margin-bottom: 0px;
}

.mobile-menu-header {
	--bs-bg-opacity: 1;
	background-color: rgba(248, 249, 250, var(--bs-bg-opacity)) !important;
	color: black;
}

.mobile-menu {
	background-color: rba(248, 249, 250) !important;
	color: #343a40;
}

.dropdown-menu {
	border: 1px solid rgba(200, 201, 202, 1) !important;
	background-image: linear-gradient(
		180deg,
		rgba(248, 249, 250, 1),
		rgba(230, 231, 232, 1)
	) !important;
	margin-bottom: 1rem !important;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.27),
		0 6px 20px 0 rgba(0, 0, 0, 0.27); /* Subtle shadow effect */
}

.dropdown-menu a {
	color: #343a40 !important;
}

.nav-divider {
	/* in old drop down */
	border: 0.3px solid rgba(220, 221, 222, 1) !important;
}

.mobile-narrow {
	margin: 0 1rem 0 1rem;
}

.dropdown-item:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.27),
		0 6px 20px 0 rgba(0, 0, 0, 0.27); /* Subtle shadow effect */
	background-image: linear-gradient(
		180deg,
		rgba(230, 231, 232, 1),
		rgba(210, 211, 212, 1)
	) !important;
	color: #333 !important;
	font-weight: 500;
}

/* Modern Focus Style for Dropdown Items */
.dropdown-item:focus {
	outline: none; /* Removing the default outline, ensure you have other visual indicators for accessibility */
	background-color: rgba(
		230,
		231,
		232,
		0.5
	); /* Soft background color indicating focus */
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.27); /* Subtle inset shadow for depth */
	color: #333 !important;
	font-weight: 500;
}

/* Modern Active Style */
.dropdown-item.active {
	background-image: linear-gradient(
		180deg,
		rgba(230, 231, 232, 1),
		rgba(210, 211, 212, 1)
	) !important; /* Slightly darker gradient for active state */
	box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.27); /* Subtle inset shadow for depth */
	color: #333 !important;
	font-weight: 500;
}

/* NOTE: DICTATES POSITION OF WHERE THE dropdown select menu 
	is positioned when user clicks on services button from 
	desktop views
*/
.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0.5rem !important;
}

@media (min-width: 1400px) {
	.nav-link {
		border-radius: 0.6rem;
	}
}

.nav-link:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.27),
		0 6px 20px 0 rgba(0, 0, 0, 0.27); /* Subtle shadow effect */
	background-image: linear-gradient(
		180deg,
		rgba(230, 231, 232, 1),
		rgba(210, 211, 212, 1)
	) !important;
	color: #333 !important;
	font-weight: 500;
}

/* Modern Focus Style for Dropdown Items */
.nav-link:focus {
	outline: none; /* Removing the default outline, ensure you have other visual indicators for accessibility */
	background-color: rgba(
		230,
		231,
		232,
		0.5
	); /* Soft background color indicating focus */
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.27); /* Subtle inset shadow for depth */
	color: #333 !important;
	font-weight: 500;
}

/* Modern Active Style */
.nav-link.active {
	background: linear-gradient(
		to bottom,
		#ffffff 0%,
		#fafafa 100%
	); /* Subtle gradient */
	background-color: white; /* Set the background to white */

	box-shadow: inset 0 2px 7px rgba(0, 0, 0, 0.27); /* Subtle inset shadow for depth */
	color: #333 !important;
	text-shadow: 0 1.5px 0 #d3d3d3; /* Slight text shadow for raised effect */
	font-weight: 500;
}

.offcanvas-body {
	flex-grow: 1;
	padding: 0px 0px !important;
	overflow-y: auto;
}

.nav-link {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
	color: #343a40 !important;
}
/*
@keyframes chaseScene {
	0%,
	100% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(100%);
	}
}

#Vector1 {
	animation: chaseScene 5s ease-in-out forwards;
}

#Vector7 {
	animation: chaseScene 5s 0.5s ease-in-out forwards; 
}

@keyframes marchIn {
	0% {
		transform: translateX(-100%);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}


#Vector,
#Vector2,
#Vector3,
#Vector4,
#Vector5,
#Vector6,
#Vector8,
#Vector9 {
	animation: marchIn 3s ease-out forwards;
}


#Vector {
	animation-delay: 0s;
}
#Vector2 {
	animation-delay: 0.4s;
}
*/

.nav-bar-brand {
	display: flex;
}

.offcanvas-close-button-class {
	color: black; /* Or any other CSS properties you want to override */
}
