/* Base styles for the dashboard overview section */
.dashboard-overview {
  background-color: #e0e0e0;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Unified card styles for metrics, actions, notifications, and activities */
.dashboard-metrics,
.quick-actions,
.notifications,
.recent-activity,
.metric-card,
.quick-action-button,
.notification-card,
.activity-card {
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 15px;
  box-shadow: 4px 4px 8px #b9b9b9, -4px -4px 8px #f0f0f0 !important; /* Neumorphic effect for depth */
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  margin-bottom: 20px;
}

.quick-action-button:hover,
.notification-card:hover,
.metric-card:hover,
.activity-card:hover,
.quick-action-button:focus,
.notification-card:focus,
.metric-card:focus,
.activity-card:focus,
.quick-action-button:active,
.notification-card:active,
.metric-card:active,
.activity-card:active {
  transform: translateY(-5px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25),
    inset 1px 1px 2px rgba(255, 255, 255, 0.5),
    inset -1px -1px 2px rgba(0, 0, 0, 0.2);
}

/* Text and icon styling */
.metric-card h3,
.notification-card p,
.activity-card p {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.metric-card p,
.activity-card button {
  font-size: 1.5rem;
  font-weight: bold;
  color: #555;
}

/* Icon styling */
.metric-card svg,
.notification-card svg,
.activity-card svg {
  color: #777;
  font-size: 2rem;
  margin-bottom: 15px;
}

.notification-card button {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
}

/* Quick action buttons specific styling */
.quick-action-button {
  background-color: #d9d9d9;
  color: #333;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.quick-action-button:active {
  transform: translateY(2px);
}

/* Quick action buttons specific styling */
.quick-action-tile {
  border: none;
  color: white !important;
  font-weight: 500 !important;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.2s ease,
    background-color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px; /* Optional: for rounded corners */

  text-shadow: 0.5px 0.5px 3px #ffffff, -0.5px -0.5px 3px #333; /* Slight text shadow for raised effect */
}

.quick-action-tile:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-1px);
}

.quick-action-tile:focus,
.quick-action-tile:active {
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.4);
  transform: translateY(2px);
}

/* Mark as read button styling */
.mark-as-read {
  background-color: #4caf50;
  color: white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  text-shadow: 0.5px 0.5px 1px #ffffff, -0.5px -0.5px 3px #333; /* Slight text shadow for raised effect */
}

.mark-as-read:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-1px);
}

.mark-as-read:focus,
.mark-as-read:active {
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.4);
  transform: translateY(2px);
}

/* New styles for when a notification is marked as read */
.notification-card.read {
  background-color: #f0f0f0; /* Lighter or more transparent background */
}

/* Adjustments for FontAwesome icons */
.fas {
  margin-right: 10px;
}

/* Responsive adjustments for the dashboard overview */
@media (min-width: 768px) {
  .dashboard-overview {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media (max-width: 320px) {
  .metric-card,
  .activity-card,
  .notification-card {
    max-width: 12rem;
  }
}

@media (min-width: 321px) and (max-width: 360px) {
  .metric-card,
  .activity-card,
  .notification-card {
    max-width: 14rem;
  }
}

/* Container for the dashboard overview or any other section you want to make horizontally scrollable */
.horizontal-scroll-container {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling */
  gap: 20px; /* Keeps space between cards */
  padding: 20px 0; /* Padding on top and bottom */
}

/* Ensuring cards within the horizontal scroll container don't grow too large */
.horizontal-scroll-container .metric-card,
.horizontal-scroll-container .quick-action-button,
.horizontal-scroll-container .notification-card,
.horizontal-scroll-container .activity-card {
  flex: 0 0 auto; /* Prevents cards from growing or shrinking */
  width: 250px; /* Fixed width for cards; adjust as needed */
}

/* Style adjustments for the scrollbar to make it more appealing */
.horizontal-scroll-container::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.horizontal-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}
