.install-prompt,
.ios-install-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  border-radius: 16px 16px 0 0;
}

.install-prompt.show,
.ios-install-prompt.show {
  transform: translateY(0);
}

.install-prompt button,
.ios-install-prompt button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px #0056b3;
}

.install-prompt button:active,
.ios-install-prompt button:active {
  box-shadow: 0 2px #0056b3;
  transform: translateY(2px);
}

.install-prompt button:hover,
.ios-install-prompt button:hover {
  background-color: #0056b3;
}

.ios-instructions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
}

.ios-instruction {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ios-instruction .add-icon {
  background-color: #ccc;
  border-radius: 18%;
  padding: 8px;
  margin-bottom: 8px;
}

.install-prompt,
.ios-install-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  border-radius: 16px 16px 0 0;
}

.install-prompt.show,
.ios-install-prompt.show {
  transform: translateY(0);
}

.install-prompt-icon-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px; /* Fixed size */
  height: 80px; /* Fixed size to maintain aspect ratio */
  margin-bottom: 20px;
  padding: 5px;
  background-color: #e0e0e0; /* Subtle background for contrast */
  border-radius: 50%; /* Ensures the container is circular */
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  overflow: hidden; /* Ensures nothing spills outside the circular boundary */
}

.install-prompt-icon {
  width: 70px; /* Makes the image fully occupy the container width */
  height: 70px; /* Adjusts height automatically to maintain aspect ratio */
  object-fit: fill; /* Ensures the image covers the space without distortion */
  border-radius: 50%; /* Ensures the image itself is also circular */
}
