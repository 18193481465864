.SalesLeads {
	text-align: left;
}

.leads .list-group {
	--bs-list-group-bg: #000;
	--bs-list-group-border-color: rgba(82, 50, 85, 0.7);
	--bs-list-group-border-width: 1px;
	--bs-list-group-border-radius: 0.6rem;
}

.create-new {
	color: #fff;
}

.leads-group {
	padding-bottom: 9rem !important;
}

.create-new-leads:hover,
.create-new-leads:focus,
.create-new-leads:active {
	background-color: #2b2b2b !important;
}

.dropdown-sales-list {
	margin-bottom: 1rem;
}

.dropdown-item-leads-list:hover,
.dropdown-item-leads-list:focus,
.dropdown-item-leads-list:active {
	background-color: rgba(82, 50, 85, 0.7) !important;
}

.email-list {
	font-size: 0.9rem !important;
}

.campaign-id-btn {
	font-size: 0.81rem !important;
}

.click-previous:hover {
	color: #e60061;
}

.click-previous:focus,
.click-previous:active {
	color: #a50b64;
}

.click-next:hover {
	color: #e60061 !important;
}

.click-next:focus,
.click-next:active {
	color: #a50b64 !important;
}

.click-next-none {
	display: none;
}
