.Login {
	padding: 30px 0;
}

.Login form {
	margin: 0 auto;
	max-width: 320px;
}

.Login form section {
	display: flex;
	width: 100%;
	margin-top: 1rem;
}

.Login form section button {
	width: 320px;
}

.login-form {
	text-align: left;
}

.login-form-submit-btn button {
	background-color: #333399;
	border-color: #333399;
}

.login-form-submit-btn button:hover,
.login-form-submit-btn button:focus,
.login-form-submit-btn button:active {
	background-color: #24256e;
	border-color: #24256e;
}

.Login form a {
	margin-top: 0.6rem;
	display: block;
	font-size: 14px;
	text-decoration-line: none;
	color: #d072c0;
}

.Login form a:hover,
.Login form a:focus,
.Login form a:active {
	color: #f095db;
}

#fg2 {
	margin-top: 1rem;
}
