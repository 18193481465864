.ResetPassword {
	padding: 0rem 0rem 6rem 0rem;
}

.ResetPassword form {
	margin: 0 auto;
	max-width: 320px;
}

/* BELOW HERE MORE WORK */

.ResetPassword form section {
	display: flex;
	width: 100%;
	margin-top: 1rem;
}

.ResetPassword form section button {
	width: 320px;
}

.reset-password-form {
	text-align: left !important;
}

.reset-password-submit-btn button {
	background-color: #333399;
	border-color: #333399;
}

.reset-password-submit-btn button:hover,
.reset-password-submit-btn button:focus,
.reset-password-submit-btn button:active {
	background-color: #24256e;
	border-color: #24256e;
}

.success p a {
	margin-top: 0.6rem;
	display: block;
	text-decoration-line: none;
	color: #d072c0;
}

.success p a:hover,
.success p a:focus,
.success p a:active {
	color: #f095db;
}

#fg5,
#fg6 {
	margin-top: 1rem;
}
