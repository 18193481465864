/* General styles for the dashboard */
.dashboard-container-sa {
  padding: 20px !important;
  min-height: 100vh;
  background-color: #e0e0e0; /* Light grey background */
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  border-radius: 9px;
  margin-bottom: 6rem;
}

/* Skeuomorphic button styles */
.dashboard-button {
  width: 100% !important;
  margin-bottom: 10px !important;
  position: relative;
  padding-left: 20px;
  text-align: left;
  height: 7.2rem;
  background-color: #e0e0e0; /* Base color */
  border: 0px solid transparent !important;
  border-radius: 9px;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Inset and outset shadows for depth */
  color: #333; /* Text color */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions for both transform*/

  text-shadow: 0.5px 0.5px 1px #ffffff, -0.5px -0.5px 3px #333; /* Slight text shadow for raised effect */
}

.dashboard-button:active,
.dashboard-button.selected {
  /* More subtle and realistic pressed effect */
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.27),
    inset -4px -4px 8px rgba(255, 255, 255, 0.27);
  transform: translateY(2px); /* Simulate the button being pressed down */
}

.number-icon {
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 52%;
  left: 20px;
  transform: translate(-10%, -100%);
  font-weight: 500;
  box-shadow: 1.5px 1.5px 3px #a3a3a3, -1.5px -1.5px 3px #ffffff; /* Shadow for depth */
}

.sa-button-label {
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  transform: translate(0%, 100%);
  margin-top: 1rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.min-w-100-sa-dash {
  min-width: 200px !important;
}

/* Specific button colors */
.btn-lawyers,
.btn-support-queries {
  background: linear-gradient(
    145deg,
    #49b0d8,
    #67c8ff
  ); /* Gradient for blue buttons */
}

.btn-court-reporters {
  background: linear-gradient(
    145deg,
    #e6a545,
    #ffcd73
  ); /* Gradient for yellow buttons */
}

.btn-depositions {
  background: linear-gradient(
    145deg,
    #c8433f,
    #f76b65
  ); /* Gradient for red buttons */
}

.btn-support-queries {
  background: linear-gradient(
    145deg,
    #4caf50,
    #81c784
  ); /* Gradient for green buttons */
}

/* Lawyers Button - Blue */
.btn-lawyers.dashboard-button:active,
.btn-lawyers.dashboard-button.selected {
  background: linear-gradient(
    145deg,
    #377fa0,
    #4f9bc2
  ); /* Slightly darker gradient */
}

/* Court Reporters Button - Yellow */
.btn-court-reporters.dashboard-button:active,
.btn-court-reporters.dashboard-button.selected {
  background: linear-gradient(
    145deg,
    #d4973a,
    #e6b155
  ); /* Slightly darker gradient */
}

/* Depositions Button - Red */
.btn-depositions.dashboard-button:active,
.btn-depositions.dashboard-button.selected {
  background: linear-gradient(
    145deg,
    #a8322d,
    #c8433f
  ); /* Slightly darker gradient */
}

/* Support Queries Button - Green */
.btn-support-queries.dashboard-button:active,
.btn-support-queries.dashboard-button.selected {
  background: linear-gradient(
    145deg,
    #388e3c,
    #66bb6a
  ); /* Slightly darker gradient */
}

/* Adjust padding and margin as needed for layout */

/* Add any additional styles for typography, custom components, etc */
.table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 1em 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.table th,
.table td {
  background-color: #fff; /* White background for cells */
  padding: 10px 20px;
}

.table th {
  text-align: left;
  font-weight: bold;
  color: #333; /* Dark text for contrast */
  background-color: #ddd; /* Slightly darker background for headers */
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.2); /* Inner shadow for depth */
}

.table tr {
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  cursor: pointer;
}

.table tr:hover {
  background-color: #f5f5f5; /* Lighter grey on hover for interactivity */
}

.table td {
  border-top: 1px solid #eaeaea; /* Subtle borders within the table */
  color: #555; /* Slightly lighter text for readability */
}

/* Pagination Buttons */
.button {
  background-color: #e0e0e0; /* Light grey background */
  color: #333; /* Dark text */
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for interaction */
}

.button:hover {
  background-color: #d4d4d4; /* Slightly darker on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Larger shadow on hover for "lifting" effect */
}

.button:active {
  background-color: #c8c8c8; /* Even darker on click */
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); /* Smaller shadow for "pressed" effect */
}

@media (min-width: 769px) {
  .mobile-card-view {
    display: none !important;
  }

  .mobile-filter-view {
    display: none !important;
  }
}

/* Display mobile cards on smaller screens */
@media (max-width: 768px) {
  .mobile-card {
    display: block;
  }

  /* Hide the table on smaller screens */
  .responsive-table {
    display: none;
  }

  .responsive-filter {
    display: none !important;
  }
}

/* Adjustments for mobile-card to look like a white paper post-it note */
.mobile-card {
  display: block;
  background-color: #ffffff; /* White color for the post-it note */
  margin: 1rem auto;
  padding: 20px;
  border-radius: 8px; /* Slightly rounded corners like a post-it note */
  position: relative;
  overflow: hidden;
  color: #333; /* Dark text for contrast */
  text-align: left;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2); /* Shadow to make it look elevated */
  transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
}

/* Adjust the .status-actions container for better alignment of buttons */
.status-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-card-content {
  position: relative;
  z-index: 10;
  /*text-shadow: 1px 1px 2px #000, -1px -1px 2px #000; /* Raised text effect */
}

/* Button styles */
.card-button {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  background-color: #4b4b4b; /* Matching the card's metallic look */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease; /* Smooth transition for a tactile feel */
}

.card-button:active {
  transform: scale(0.85); /* More dramatic scale down for pressing */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: add an inset shadow for depth */
}

.card-button.approve {
  background-color: #4caf50; /* Green for approve */
}

.card-button.reject {
  background-color: #f44336; /* Red for reject */
}

.card-button.pause {
  background-color: #ff9800; /* Orange for pause */
}

.card-button.appeal-approve {
  background-color: #4caf50; /* Green for appeal approve */
}

.card-button.appeal-deny {
  background-color: #f44336; /* Red for appeal deny */
}

.card-button.cancel {
  background-color: #ff5722; /* Distinct color for cancel */
}

.card-button.hire {
  background-color: #4caf50; /* Green for hire */
}

.card-button.deny {
  background-color: #f44336; /* Red for deny */
}

.card-button.fire {
  background-color: #ff5722; /* Distinct color for fire */
}

.card-button.leave {
  background-color: #ffeb3b; /* Yellow for leave */
}

.card-button.appeal-rehire {
  background-color: #4caf50; /* Green for appeal rehire */
}

.card-button.appeal-fire {
  background-color: #f44336; /* Red for appeal fire */
}
