:root {
  --primary-color: #333; /* dark sgade of gray */
  --background-color: #fff; /* white */
  --url-color: #0000cc; /* dark blue */
}

[data-theme="dark"] {
  --primary-color: #fff; /* white */
  --background-color: #333; /* dark sgade of gray */
  --url-color: #ffdab9; /* pastel yellow */
}

body {
  margin: 0;
  padding: 0;

  background-color: var(--background-color);
  color: var(--primary-color);

  font-size: 16px !important;

  font-family: "Poppins", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

a {
  color: var(--url-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem !important;
}

inut[type="file"] {
  width: 100%;
}

/* Default CSS Here */
.geosuggest {
  font-size: 1rem;
  position: relative;
  width: 100%;
  margin: 0px;
  text-align: left;
}

.geosuggest__input {
  width: 100%;
  border: none;
  box-shadow: 0 0 1px #3d464d;
  -moz-box-shadow: 0 0 1px #3d464d;
  -webkit-box-shadow: 0 0 1px #3d464d;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;

  height: 38px;
  padding: 6px;
  font-size: 1rem;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
  /* TODO: Possioble throw away; */
  line-height: 1.333333333;
  border-radius: 4px;
  /* TODO: Possioble throw awaysssss; */
  display: block;
  color: black;
  background-color: white;
  background-image: none;
  border: 1px solid #ced4da;
  -webkit-box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.geosuggest__input:focus {
  color: black;
  background-color: white;
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.geosuggest__input::placeholder {
  color: #888; /* Placeholder text color */
  font-weight: 300; /* Placeholder font weight - can be lighter than the input text */
  opacity: 1; /* Full opacity - you can set this to less than 1 to make it lighter */
  font-style: italic; /* Optional: Italicize the placeholder text to differentiate it from input text */
}

#geosuggest__list {
  z-index: 1;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 24em;
  padding: 0px;
  margin-top: 6px;
  background-color: white;
  border: 1px solid #ced4da;
  padding-left: 0px;
  border-top-width: 1px;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  font-size: 16px;
  cursor: pointer;
  padding: 0.6rem;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background-image: linear-gradient(180deg, #f8f8f8, #e0e0e0) !important;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}

/** NOTE: Determine need
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
*/
