.App {
  text-align: center;
  background-image: linear-gradient(
    -18deg,
    white,
    white
  ); /* NOTE: THEME Background Color for main app page  */
  color: black;
  margin-bottom: 20rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nucleus-form-fields {
  /* NOTE: THEME Form Fields for main app page  */
  background-color: white !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-family: "Montserrat";
  color: black !important;
}

.nucleus-form-fields-read-only {
  /* NOTE: THEME Form Fields for main app page  */
  background-image: linear-gradient(180deg, #f8f8f8, #e0e0e0) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-family: "Montserrat";
  color: black !important;
}

.nucleus-form-fields::placeholder {
  color: #888; /* Placeholder text color */
  font-weight: 300; /* Placeholder font weight - can be lighter than the input text */
  opacity: 1; /* Full opacity - you can set this to less than 1 to make it lighter */
  font-style: italic; /* Optional: Italicize the placeholder text to differentiate it from input text */
}

.responsive-table {
  max-width: 100% !important;
  overflow-x: auto !important;
}

.responsive-table th,
.responsive-table td {
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.responsive-table th {
  white-space: wrap;
}

.responsive-table td {
  white-space: normal;
  word-wrap: break-word;
}
