.footer-menu {
	/* height: 80px; */
	--bs-bg-opacity: 1;
	background-color: #213b65 !important;
	padding: 10px 0;
}

.ftr {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: -1;
}

.ftr-icon {
	max-width: 3% !important;
	max-height: 3%;
}

.ftr-icon-md {
	max-width: 2%;
	max-height: 2%;
}

.ftr-icon-xs {
	max-width: 4%;
	max-height: 4%;
}

.ftr-link {
	color: #a1aab4;
	text-decoration: none;
}

.ftr-icon {
	max-width: 0.75% !important;
	max-height: 0.75%;
}

.ftr-icon-md {
	max-width: 2%;
	max-height: 2%;
}

.ftr-icon-xs {
	max-width: 4%;
	max-height: 4%;
}
.copyright {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	line-height: 1.6;
	color: #a1aab4;
	font-size: 10px;
	margin-right: 0px !important;
	margin-left: 0px !important;
}

/* For tablets and larger devices */
@media (min-width: 360px) {
	.copyright {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		line-height: 1.6;
		color: #a1aab4;
		font-size: 12px;
		margin-right: 0px !important;
		margin-left: 0px !important;
	}
}

/* For tablets and larger devices */
@media (min-width: 768px) {
	.copyright {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		line-height: 1.6;
		color: #a1aab4;
		font-size: 14px;
		margin-right: 0px !important;
		margin-left: 0px !important;
	}
}

/* For desktops and larger devices */
@media (min-width: 1024px) {
	.copyright {
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		line-height: 1.6;
		color: #a1aab4;
		font-size: 16px;
		margin-right: 0px !important;
		margin-left: 0px !important;
	}
}
