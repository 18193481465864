.NewSalesLead {
  text-align: left;
}

.NewSalesLead h4 {
}

.NewSalesLead form textarea {
  height: 300px;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.new-sales-order-file-upload-group {
  margin-top: 1rem;
}

.add-campaign-btn {
  margin-bottom: 1rem;
}
