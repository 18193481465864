.privacy-policy {
	/* Skeuomorphic enhancements */
	text-align: left;
	background: linear-gradient(
		to bottom,
		#ffffff 0%,
		#fafafa 100%
	); /* Subtle gradient */
	background-color: white; /* Set the background to white */
	border: 1px solid #d3d3d3; /* Light grey border for subtle definition */

	box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.27); /* Subtle inset shadow for depth */
	padding: 15px; /* Add some padding around the text */
	padding-top: 20px; /* Add some padding around the text */
	border-radius: 6px; /* Optional: rounded corners */

	text-shadow: 0 1.5px 0 #d3d3d3; /* Slight text shadow for raised effect */
	line-height: 1.6; /* Improved line height for readability */
	color: #333; /* Dark grey color for the text */
	margin-bottom: 4rem;
	height: 100%; /* Fixed height */
	overflow-y: auto; /* Enable vertical scrolling */
}
