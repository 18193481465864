.lp-container {
  background-color: #e6e6e6; /* Neutral background to complement shadows */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #f0f0f0; /* Soft, extended shadows for depth */
  border: none;
}

.profile-image-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px; /* Fixed size */
  height: 120px; /* Fixed size to maintain aspect ratio */
  margin-bottom: 20px;
  padding: 5px;
  background-color: #e0e0e0; /* Subtle background for contrast */
  border-radius: 50%; /* Ensures the container is circular */
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  overflow: hidden; /* Ensures nothing spills outside the circular boundary */
}

.profile-image {
  width: 100%; /* Makes the image fully occupy the container width */
  height: auto; /* Adjusts height automatically to maintain aspect ratio */
  object-fit: cover; /* Ensures the image covers the space without distortion */
  border-radius: 50%; /* Ensures the image itself is also circular */
}

.profile-attribute {
  font-weight: 600;
  display: flex;
  align-items: center; /* This centers the items vertically within the container */
  justify-content: start; /* This aligns items to the start of the container, you can adjust as needed */
}

.profile-value {
  margin-left: 2.8rem !important;
  margin-bottom: 1rem !important;
  color: darkblue !important;
}

.icon-circle {
  background-color: white;
  border-radius: 50%; /* Makes the background circle */
  padding: 6px; /* Adjusts the size of the circle */
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  margin-right: 1rem; /* Adds some space between the icon and the text */
  width: 1rem;
}

.user-details p,
.payment-method p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
  text-shadow: 1px 1px 2px #ffffff; /* Soft text shadow for depth */
}

.payment-option-button {
  margin: 12px 9px; /* Adds space around buttons */
  background-color: #e0e0e0; /* Base color */
  border: none;
  padding: 10px 15px;
  border-radius: 9px;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  font-weight: bold;
  transition: all 0.2s ease;
}

.payment-option-button:hover {
  background-color: #dcdcdc; /* Slightly darker on hover */
}

.payment-option-button:active,
.payment-option-button:focus {
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.3),
    /* Darker shadow on top/left */ inset -3px -3px 6px rgba(255, 255, 255, 0.4); /* Lighter "shadow" (highlight) on bottom/right */
}

.payment-option-button.active {
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.3),
    /* Darker shadow on top/left */ inset -3px -3px 6px rgba(255, 255, 255, 0.4); /* Lighter "shadow" (highlight) on bottom/right */
  background-color: #007bff; /* Active button background color */
  color: white; /* Active button text color */
}

.skeuomorphic-element {
  background: #e0e5ec; /* Light grey background, adjust as needed */
  border-radius: 20px; /* Soft rounded corners for the container */
  /* Adjusted box shadow for a more pronounced embossed effect */
  box-shadow: inset 4px 4px 10px #a3a3a3,
    /* Outer shadow for depth */ inset -4px -4px 10px #ffffff; /* Inner light for a soft glow */
  padding: 20px;
  margin-bottom: 20px;
}

.skeuomorphic-element p {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}

.icon-verified,
.icon-unverified {
  margin-left: 10px;
  filter: drop-shadow(2px 2px 2px #a3a3a3); /* Soft shadow for icon depth */
}

.icon-verified {
  color: #28a745; /* Green for verified */
}

.icon-unverified {
  color: #dc3545; /* Red for unverified */
}

.lp-button-edit {
  display: block;
  width: 100%;
  margin-top: 20px;
  background-color: #e0e0e0; /* Base color for button */
  border: none;
  padding: 12px 0;
  border-radius: 10px;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic shadows for soft depth */
  color: #333;
  font-weight: bold;
  transition: all 0.2s ease;
}

.lp-button-edit:hover {
  background-color: #dcdcdc; /* Slightly darker on hover for tactile feedback */
}

.lp-button-edit:active {
  box-shadow: inset 4px 4px 8px #a3a3a3, inset -4px -4px 8px #ffffff; /* Inset shadows for pressed effect */
  transform: translateY(4px);
}

/* Additional styles for form elements and other components can follow the same design principles */
.ellipsis-button {
  background-color: #e0e0e0; /* Adjust to your theme */
  border: none;
  border-radius: 50%; /* Circular shape */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 8px 8px 15px #bebebe, -8px -8px 15px #ffffff;
  outline: none;
  cursor: pointer;
  margin-bottom: 2rem;
}

.ellipsis-button:focus,
.ellipsis-button:active {
  box-shadow: inset 8px 8px 15px #bebebe, inset -8px -8px 15px #ffffff;
}

.user-details-dropdown {
  display: flex;
  justify-content: flex-end;
}

.payment-field {
  border-radius: 10px; /* Slightly rounded corners for a softer look */
  width: 100%; /* Adjust width as needed */
  margin-bottom: 20px;
  background: #e0e5ec; /* Light background color, adjust to match your app's theme */
  box-shadow: inset 2px 2px 5px #a3a3a3,
    /* Inner shadow for depth */ inset -2px -2px 5px #ffffff; /* Inner light for a raised effect */
  font-size: 16px;
  transition: 200ms;
}

.payment-field input {
  border: none; /* Remove border */
  width: 100%;
  border-radius: 10px; /* Match parent's border-radius */
  padding: 10px; /* Slightly larger padding for a better look */
  background: transparent; /* Ensure the input background matches the field */
  color: #333; /* Text color */
  font-size: 16px; /* Adjust font size as needed */
  box-shadow: none; /* Remove any default shadows */
}

.payment-field input:focus {
  outline: none; /* Remove focus outline */
  box-shadow: inset 1px 1px 2px #a3a3a3,
    /* More pronounced inset shadow for depth */ inset -1px -1px 2px #ffffff; /* More pronounced inner highlight */
}

.payment-field:hover {
  box-shadow: inset 3px 3px 8px #a3a3a3,
    /* More pronounced inner shadow for depth */ inset -3px -3px 8px #ffffff; /* More pronounced inner light for a raised effect */
}

.payment-label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

#payment {
  margin: 1rem 0rem 1rem 0rem;
  display: flex;
  flex-direction: column; /* Stack fields vertically */
  align-items: flex-start; /* Align items to the start (left) */
  justify-content: space-between;
  padding: 20px; /* Add some padding */
  background: #e0e5ec; /* Match the fields' background */
  border-radius: 20px; /* Soft rounded corners for the container */
  box-shadow: inset 4px 4px 10px #a3a3a3,
    /* Outer shadow for depth */ inset -4px -4px 10px #ffffff; /* Inner light for a soft glow */
}

#ccnumber,
#ccexp,
#cvv {
  font-size: 16px; /* Standardize font size */
}

#ccexp,
#cvv {
  max-width: 97%;
}

#cvv,
.cvv-label {
  margin-left: 3%;
}

.exp-cvv-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.exp-cvv-container > div {
  flex: 1;
}

@media (max-width: 768px) {
  .exp-cvv-container {
    flex-direction: column;
  }

  #cvv,
  .cvv-label {
    margin-left: 0; /* Remove the margin-left on smaller screens */
  }

  #ccexp,
  #cvv {
    max-width: 100%;
  }

  .payment-field {
    width: 100%; /* Make payment fields take full width */
  }
}

.bank-details-container {
  width: 100%;
}

/* This ensures the fields are stacked on small screens */
.bank-details-container > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; /* Adjust spacing as needed */
}

/* This will place the fields side by side on larger screens */
@media (min-width: 768px) {
  .bank-details-container {
    display: flex;
    justify-content: space-between;
  }

  .bank-details-container > div {
    flex: 1;
    /* Adjust the margin to ensure there's space between the fields */
    margin-right: 1rem;
  }

  /* Remove margin from the last field to avoid extra spacing on the right */
  .bank-details-container > div:last-child {
    margin-right: 0;
  }
}
