/* General styles for the dashboard */
.dashboard-container-ld {
  padding: 20px;
  background-color: #e0e0e0;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff;
  border-radius: 9px;
  margin-bottom: 6rem;
}

@media (max-width: 768px) {
  .dashboard-container-ld {
    padding: 20px;
    background-color: #e0e0e0;
    box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff;
    border-radius: 9px;
    margin-bottom: 6rem;
    /* Increased min-height for smaller screens */
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px; /* Adjust the gap between checkboxes as needed */
}

.flex-container .form-check {
  flex: 1 1 auto; /* Allow the checkboxes to grow and shrink */
}

/* Skeuomorphic design for form elements */
.skeuomorphic-element {
  background: #e0e5ec;
  border-radius: 20px;
  box-shadow: inset 4px 4px 10px #a3a3a3, inset -4px -4px 10px #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

/* Styles for the calendar component */
.react-calendar {
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

.form-label-left .form-label {
  display: flex;
}

.form-label-left .form-check label {
  display: flex;
}

.form-label-left input {
  margin-bottom: 1rem;
}

.form-label-left .form-check {
  margin-bottom: 1rem;
}

#nodUpload {
  margin-bottom: 1rem;
}

/* Add more styles as needed to match the design of LawyerDashboard */
.table-row-depo {
}

.justify-content-center {
  justify-content: center !important;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 769px) {
  .calendar-syle {
    width: 40%;
    height: 40%;
  }
}

/* Display mobile cards on smaller screens */
@media (max-width: 768px) {
  .calendar-syle {
    width: 100%;
    height: 40%;
  }
}

.depositions-list {
  margin-top: 1rem;
}

.deposition-item {
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid #ccc;
  margin-bottom: 0.5rem;
}

.deposition-item:hover {
  background-color: #f0f0f0;
}

.deposition-details {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: #fafafa;
}

.skeumorphic-option-button {
  margin: 12px 9px; /* Adds space around buttons */
  background-color: #e0e0e0; /* Base color */
  border: none;
  padding: 10px 15px;
  border-radius: 9px;
  box-shadow: 4px 4px 8px #a3a3a3, -4px -4px 8px #ffffff; /* Neumorphic effect for depth */
  font-weight: bold;
  transition: all 0.2s ease;
}

.skeumorphic-option-button:hover {
  background-color: #dcdcdc; /* Slightly darker on hover */
}

.skeumorphic-option-button:active,
.payment-option-button:focus {
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.3),
    /* Darker shadow on top/left */ inset -3px -3px 6px rgba(255, 255, 255, 0.4); /* Lighter "shadow" (highlight) on bottom/right */
}

.skeumorphic-option-button.active {
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.3),
    /* Darker shadow on top/left */ inset -3px -3px 6px rgba(255, 255, 255, 0.4); /* Lighter "shadow" (highlight) on bottom/right */
  background-color: #007bff; /* Active button background color */
  color: white; /* Active button text color */
}
